import { z } from "zod";

export const UNIQUE_CAMPAIGN_URL_TAG = "{{unique-campaign-url}}";

export enum AppLocale {
  DeDe = "de_DE",
  EnGb = "en_GB",
  FrFr = "fr_FR",
  NlNl = "nl_NL",
  ItIt = "it_IT",
  EsEs = "es_ES",
  CsCz = "cs_CZ",
  PtPt = "pt_PT",
}

export enum AppWipLocale {
  PlPl = "pl_PL",
  DaDk = "da_DK",
  HuHu = "hu_HU",
}

export function isAppLocale(value: string): value is AppLocale {
  return (Object.values(AppLocale) as string[]).includes(value);
}

export enum AppLanguage {
  De = "de",
  En = "en",
  Fr = "fr",
  Nl = "nl",
  It = "it",
  Es = "es",
  Cz = "cs",
  Pt = "pt",
}

export function isAppLanguage(value: string): value is AppLanguage {
  return (Object.values(AppLanguage) as string[]).includes(value);
}

export enum UrlPrefix {
  Https = "https://",
  Http = "http://",
}

export interface CountryTranslations {
  alpha2: string;
  alpha3: string;
  id: string;
  name: string;
}

export enum ExternalUrl {
  Terms = "terms",
  Privacy = "privacy",
  ContentEngagement = "contentEngagement",
}

export const UrlPrefixes = ["https://", "http://"] as const;

export const AppDataCountryParser = z
  .object({
    code: z.string(),
    name: z.string(),
    translatedName: z.string().optional(),
    order: z.number().optional(),
  })
  .strict();

export type AppDataCountry = z.infer<typeof AppDataCountryParser>;

export const AppDataPrefixParser = z
  .object({
    code: z.string(),
    name: z.string(),
    translatedName: z.string().optional(),
    prefix: z.string(),
  })
  .strict();

export type AppDataPrefix = z.infer<typeof AppDataPrefixParser>;

export const AppDataTitleParser = z
  .object({
    code: z.string(),
    name: z.string(),
  })
  .strict();

export type AppDataTitle = z.infer<typeof AppDataTitleParser>;

export const AppDataSectorParser = z
  .object({
    id: z.number(),
    name: z.string(),
  })
  .strict();

export type AppDataSector = z.infer<typeof AppDataSectorParser>;

export const AppDataGenderParser = z
  .object({
    code: z.string(),
    name: z.string(),
  })
  .strict();

export type AppDataGender = z.infer<typeof AppDataGenderParser>;

export const AppDataLanguageParser = z
  .object({
    code: z.union([z.nativeEnum(AppLocale), z.nativeEnum(AppWipLocale)]),
    name: z.string(),
    notForUI: z.boolean().optional(),
  })
  .strict();

export type AppDataLanguage = z.infer<typeof AppDataLanguageParser>;

export const AppDataCurrencyParser = z
  .object({
    code: z.string(),
    name: z.string(),
  })
  .strict();

export type AppDataCurrency = z.infer<typeof AppDataCurrencyParser>;

export const AppDataPostTagsParser = z
  .object({
    key: z.string(),
    tag: z.string(),
  })
  .strict();

export type AppDataPostTags = z.infer<typeof AppDataPostTagsParser>;

export const AppDataParser = z.object({
  banner: z.string(),
  bannerLink: z.string(),
  browserLanguage: z.string(),
  countries: AppDataCountryParser.array(),
  currencies: AppDataCurrencyParser.array(),
  currency: z.string(),
  facebookAdCTAOptions: z.record(z.string()),
  genders: AppDataGenderParser.array(),
  isUnderMaintenance: z.boolean(),
  languages: AppDataLanguageParser.array(),
  maxUploadFileSize: z.coerce.number(),
  notificationEmail: z.string().nullable(),
  postTags: AppDataPostTagsParser.array(),
  prefixes: AppDataPrefixParser.array(),
  sectors: AppDataSectorParser.array(),
  titles: AppDataTitleParser.array(),
});

export type AppData = z.infer<typeof AppDataParser>;
