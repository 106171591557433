import {
  ImageRatio,
  PartnerFacebookPageClaimStatus,
} from "../enums/partner.enums";
import { Address } from "./address";
import { Image } from "./image";
import { SerializableObject } from "./serializable-object";
import { User } from "./user";

import { AppLocale, AppDataSector, UrlPrefix } from "./appData";

export class Partner extends SerializableObject {
  id!: number;
  language!: AppLocale;
  addresses!: Address[];
  areGoogleAdsAuthorized!: boolean;
  doNotHaveFacebookPage!: boolean;
  billingAddressId!: number;
  blogUrl!: string;
  blogUrlPrefix!: UrlPrefix;
  companyAdditionalInformation!: string;
  companyName!: string;
  contactPersonFirstName!: string;
  contactPersonGender!: string;
  contactPersonLastName!: string;
  contactPersonTitle!: string;
  currency!: string;
  emails!: string[];
  doNotHaveLinkedinOrganization!: boolean;
  facebook!: string;
  facebookAccessTokenScopes!: string[];
  hasConnectedToFacebook!: boolean;
  facebookPageClaimStatus!: PartnerFacebookPageClaimStatus;
  facebookPageId!: string;
  facebookPageLikes!: number;
  facebookPageName?: string;
  facebookPagePicture?: string;
  files!: Image[];
  frequencyDistribution!: string;
  instagram!: string;
  isContactCustomerData!: boolean;
  isIncomplete!: boolean;
  isProspect!: boolean;
  logos!: Image[];
  numSubscribers!: number;
  offerNewsletterSubscription!: boolean;
  other!: string;
  phone!: string;
  phoneSecond!: string;
  prefixPhone!: string;
  prefixPhoneSecond!: string;
  privacyPolicyLink!: string;
  privacyPolicyLinkPrefix!: UrlPrefix;
  sectors!: AppDataSector[];
  sendCustomerDataEmail!: string;
  shippingAddressId!: number;
  slug!: string;
  twitter!: string;
  users!: User[];
  vatNumber!: string;
  webUrl!: string;
  webUrlPrefix!: UrlPrefix;
  sharedFacebookConnectionPartnerIds!: number[];
  instagramAccountId!: string | null;
  instagramAccountName!: string | null;
  instagramAccountPicture!: string | null;
  linkedinOrganizationUrn!: string | null;
  linkedinOrganizationName!: string | null;

  public static create(): Partner {
    const partner = new Partner();
    partner.areGoogleAdsAuthorized = true;
    partner.doNotHaveFacebookPage = false;
    partner.emails = [];
    partner.logos = [];
    partner.sectors = [];
    partner.files = [];
    partner.users = [];
    // These IDs are temporary and updated
    // when the partner is created
    partner.billingAddressId = 1;
    partner.shippingAddressId = 2;
    partner.addresses = [
      Address.createBilling(partner.billingAddressId),
      Address.createShipping(partner.shippingAddressId),
    ];
    partner.language = AppLocale.DeDe;
    return partner;
  }

  public get hasAllConnectionsSet(): boolean {
    return (
      this.areGoogleAdsAuthorized &&
      this.hasConnectedToFacebook &&
      this.isAuthorizedFBAds &&
      this.isConnectedToInstagram
    );
  }

  public get hasSharedFacebookConnectionPartnerIds(): boolean {
    return (
      this.sharedFacebookConnectionPartnerIds &&
      this.sharedFacebookConnectionPartnerIds.length > 0
    );
  }

  public get totalSharedFacebookConnectionPartnerIds(): number {
    return this.sharedFacebookConnectionPartnerIds
      ? this.sharedFacebookConnectionPartnerIds.length
      : 0;
  }

  public get isAuthorizedFBAds(): boolean {
    if (!this.hasConnectedToFacebook) {
      return false;
    }
    return (
      this.facebookPageClaimStatus === PartnerFacebookPageClaimStatus.Authorized
    );
  }

  public get isConnectedToFB(): boolean {
    return this.hasConnectedToFacebook;
  }

  public get isConnectedToInstagram(): boolean {
    return !!this.instagramAccountId;
  }

  public get isConnectedToLinkedIn(): boolean {
    return !!this.linkedinOrganizationUrn;
  }

  get isPartnerMissingFacebookPermissions(): boolean {
    return !this.facebookAccessTokenScopes.includes("read_insights");
  }

  public get billingAddress(): Address {
    const addresses = this.addresses ? this.addresses : [];
    const billingAddress = addresses.find(
      (a) => a.id === this.billingAddressId,
    );
    return billingAddress || Address.createBilling(this.billingAddressId);
  }

  get shippingAddress(): Address {
    const addresses = this.addresses ? this.addresses : [];
    const shippingAddress = addresses.find(
      (a) => a.id === this.shippingAddressId,
    );
    return shippingAddress || Address.createShipping(this.shippingAddressId);
  }

  get squareLogo(): string | undefined {
    const squaredLogos = (this.logos ?? []).filter(
      (logo) => logo.ratio === ImageRatio.Squared,
    );

    return squaredLogos[0]?.file;
  }

  public get googleAdsLogoUrl(): string | undefined {
    if (this.logos?.length) {
      return this.logos[0].file;
    }
    return undefined;
  }

  public get hasUploadedFiles(): boolean {
    if (!this.logos && !this.files) {
      return false;
    }

    return this.logos.length > 0 || this.files.length > 0;
  }

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "addresses":
        return propertyValue.map((address: any) => new Address(address));
      case "files":
      case "logos":
        return propertyValue.map((image: any) => new Image(image));
      case "users":
        return propertyValue.map((user: any) => new User(user));
      default:
        return propertyValue;
    }
  }
}

export interface PartnerStatusVM {
  readonly id: PartnerStatusVM;
}
