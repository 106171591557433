import { ImageRatio } from "../enums/partner.enums";
import { SerializableObject } from "./serializable-object";

export class Image extends SerializableObject {
  id!: number;
  file!: string;
  fileType!: string;
  name!: string;
  ratio?: ImageRatio;
}
