import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { DialogService } from "./dialog.service";

enum NotificationType {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

@Injectable()
export class NotificationService {
  constructor(
    private readonly toastr: ToastrService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {}

  public success(message: string, title?: string): void {
    this.openToastr(NotificationType.Success, message, title);
  }

  public info(message: string, title?: string): void {
    this.openToastr(NotificationType.Info, message, title);
  }

  public warning(message: string, title?: string): void {
    this.openToastr(NotificationType.Warning, message, title);
  }

  public error(message: string, title?: string): void {
    this.openToastr(NotificationType.Error, message, title);
  }

  private openToastr(
    type: NotificationType,
    message: string,
    title?: string,
  ): void {
    this.toastr[type](
      this.translateService.instant(message),
      title ? this.translateService.instant(title) : "",
      {
        closeButton: true,
      },
    );
  }

  public async errorDialog(
    message: string,
    title: string,
    details?: Record<string, string>,
    moreInfo?: string,
  ): Promise<void> {
    await this.dialogService.showError({
      message: message ? this.translateService.instant(message) : "",
      title: title ? this.translateService.instant(title) : "",
      details: details,
      moreInfo: moreInfo,
    });
  }
}
