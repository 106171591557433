// TODO(fix-video-entity): Obviously this sucks. We reference to the same
// """entity""" in two different places; but then the API is returning different
// data. This needs to be split into two different entities.
export class VideoEntity {
  constructor(
    // TODO(fix-video-entity): Entity A
    public readonly id: number,
    public readonly url: string,
    public readonly description: string,
    public readonly fileType: string,
    public readonly imagePublicId: string,
    public title: string, // TODO: Change to `readonly`
    public readonly videoDuration: number,
    public readonly videoPublicId: string,
    public readonly videoUrl: string,
    public readonly widthXHeight: number,

    // TODO(fix-video-entity): Entity B
    public readonly downloadLink: string,
    public readonly image: string,
    public readonly link: string,
    public readonly videoName: string,
  ) {}
}
