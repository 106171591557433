<div>
  <div>
    <app-facebook-post-mock-up
      [isForList]="true"
      *ngIf="showFacebookPreview()"
      [showFooterReactions]="false"
      [borderType]="BORDER_TYPE_RIGHT_EMPTY"
      [partner]="partner"
      [post]="post"
    />
    <app-instagram-post-mock-up
      *ngIf="showInstagramPreview()"
      [partner]="partner"
      [post]="post"
    />
    <app-google-ad-mock-up *ngIf="post.isGoogleAd" [post]="post" />
    <app-linkedin-post-mock-up *ngIf="post.isLinkedInPost" [post]="post">
    </app-linkedin-post-mock-up>
  </div>
  <div *ngIf="post.isFacebookPost" class="preview-footer">
    <div class="preview-footer-text">
      <img
        *ngIf="facebookPreviewType === FacebookPreviewType.Facebook"
        src="../../../../../assets/images/icons/ic_facebook.svg"
      />
      <img
        *ngIf="facebookPreviewType === FacebookPreviewType.Instagram"
        src="../../../../../assets/images/icons/ic_instagram.svg"
      />
      <span *ngIf="facebookPreviewType === FacebookPreviewType.Facebook">
        {{ "shared.facebookPreview" | translate }}
      </span>
      <span *ngIf="facebookPreviewType === FacebookPreviewType.Instagram">
        {{ "shared.instagramPreview" | translate }}
      </span>
    </div>
    <div class="preview-footer-button">
      <div
        *ngIf="showMediaToggleButton()"
        class="instagram-post-preview-button"
      >
        <button
          class="facebook-instagram-preview-button"
          *ngIf="facebookPreviewType === FacebookPreviewType.Facebook"
          (click)="toggleMediaPreviewType()"
        >
          <span>{{ "shared.preview" | translate }}</span>
          <img
            src="../../../../../assets/images/icons/ic_instagramContent.svg"
          />
        </button>
        <button
          *ngIf="facebookPreviewType === FacebookPreviewType.Instagram"
          class="facebook-instagram-preview-button"
          (click)="toggleMediaPreviewType()"
        >
          <span>{{ "shared.preview" | translate }}</span>
          <img
            src="../../../../../assets/images/icons/ic_facebookContent.svg"
          />
        </button>
      </div>
    </div>
  </div>
</div>
