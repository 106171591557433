export class AdMetrics {
  constructor(
    public readonly conversions: number,
    public readonly impressions: number,
    public readonly inlinePostEngagement: number,
    public readonly reach: number,
    public readonly spend: number,
    public readonly uniqueClicks: number,
  ) {}
}
