<div
  class="fb-post"
  [ngClass]="{
    'fb-post--no-min-height': noMinHeight === true,
    'fb-post--no-border': noBorder === true,

    'fb-post--for-list': isForList,
    'fb-post--full-border': borderType === MockBorderType.Full,
    'fb-post--carousel': post.mediaType === 'CAROUSEL'
  }"
  *ngIf="post"
>
  <div class="dark-post-badge" *ngIf="post.isDarkPost">
    <img src="/assets/images/icons/ic_dark_post_Calendar.svg" />
    {{ "shared.darkPost" | translate }}
  </div>
  <div class="fb-user">
    <img src="{{ facebookPagePicture }}" class="fb-user-image" loading="lazy" />
    <div>
      <span class="fb-user-name">{{ facebookPageName | translate }}</span>
      <span class="fb-user-location">
        {{ postDate | localizeDate: "mediumDate" }}
        {{ postDate | localizeDate: "shortTime" }} &bull;
        <span>{{ "shared.bySocialPALS" | translate }}</span>
        <img src="/assets/images/icons/ic_fb_globe.png" width="11" />
      </span>
    </div>
  </div>
  <!-- Post failed -->
  <div class="processing-label" *ngIf="postingToPlatformFailed">
    {{ "shared.errorPostingToFacebook" | translate }}
  </div>
  <div class="fb-text" [innerHTML]="post.text"></div>
  <div class="fb-image">
    <!-- 360 IMAGE -->
    <app-panoramic-viewer
      *ngIf="post.mediaType === ContentType.Image360 && post.image"
      [imageUrl]="post.image"
    />
    <!-- IMAGE & GIF -->
    <img
      *ngIf="post.isRenderedAsImage"
      [src]="post.image"
      alt=""
      loading="lazy"
    />
    <!-- CAROUSEL -->
    <ng-container *ngIf="post.mediaType === ContentType.Carousel">
      <app-carousel>
        <div
          carouselItem
          *ngFor="let item of post.carouselSlides"
          [image]="item.url"
          [text]="item.title"
          [videoUrl]="item.videoUrl"
          [link]="postLink"
        ></div>
      </app-carousel>
    </ng-container>
    <!-- VIDEO -->
    <video
      style="width: 100%"
      *ngIf="post.mediaType === ContentType.Video"
      src="{{ post.videoUrl }}"
      controls
      preload="metadata"
    ></video>
  </div>
  <div *ngIf="showFooterReactions" class="fb-reactions">
    <div class="fb-likes-section">
      <img src="/assets/images/icons/ic_facebookLikeBlue.svg" />
      &nbsp;<span>10</span>
    </div>
    <span class="fb-comments-section">{{
      "shared.oneComment" | translate
    }}</span>
  </div>
  <div *ngIf="showFooterStats" class="fb-actions">
    <ul>
      <li>
        <img src="/assets/images/icons/ic_fb_like.png" width="18" />
        <ng-container>{{ "shared.like" | translate }}</ng-container>
      </li>
      <li>
        <img src="/assets/images/icons/ic_fb_comment.png" width="18" />
        <ng-container>{{ "shared.comment" | translate }}</ng-container>
      </li>
      <li>
        <img src="/assets/images/icons/ic_fb_share.png" width="18" />
        <ng-container>{{ "shared.share" | translate }}</ng-container>
      </li>
    </ul>
  </div>
</div>
