export enum PostFileMediaType {
  Video = "VIDEO",
  Image = "IMAGE",
}

export enum PostPartnerStatus {
  Approved = "PARTNER_APPROVED",
  Pending = "PARTNER_PENDING",
  Discarded = "PARTNER_DISCARDED",
}

export enum Platform {
  Facebook = "facebook",
  Instagram = "instagram",
  GoogleAds = "googleAds",
  LinkedIn = "linkedin",
}

export enum PostPlatform {
  Facebook = "FACEBOOK",
  Google = "GOOGLE",
  LinkedIn = "LINKEDIN",
}

export enum PostTargetPlatforms {
  Facebook = "FACEBOOK",
  Instagram = "INSTAGRAM",
  LinkedIn = "LINKEDIN",
}

export enum PostLogStatus {
  Scheduled = "scheduled",
  Published = "published",
}

export enum CampaignPostContentType {
  Image = "IMAGE",
  Image360 = "IMAGE_360",
  Carousel = "CAROUSEL",
  Gif = "GIF",
  Video = "VIDEO",
  LinkPost = "LINK_POST",
  ResponsiveImage = "RESPONSIVE_DISPLAY",
}

export enum LinkedInPostContentType {
  Image = "IMAGE",
  MultiImage = "MULTI_IMAGE",
  Video = "VIDEO",
}
