import dayjs from "dayjs";
import { Brand } from "../../features/brand/domain/models/brand";
import {
  AdActionType,
  CampaignBudgetType,
  CampaignCurrency,
  CampaignLandingPageLinkType,
  CampaignType,
  Gender,
  ObjectiveType,
  PaymentType,
} from "../enums/campaign.enums";

import { SerializableObject } from "./serializable-object";

export class Campaign extends SerializableObject {
  id!: number;
  active!: number;
  adDuration!: number;
  audiences!: string;
  brand!: Brand;
  budget!: number;
  campaignUrl!: string;
  currency!: CampaignCurrency;
  currencySymbol!: string;
  startDate!: Date;
  endDate!: Date;
  fbCampaignStartDate!: Date;
  fbCampaignEndDate!: Date;
  fbCampaignId!: string;
  gender!: Gender;
  includePos!: boolean;
  listImageUrl!: string;
  maxAdsCampaignPartner!: number;
  maxAge!: number;
  minAge!: number;
  name!: string;
  objective!: ObjectiveType;
  paymentType!: PaymentType;
  projectName!: string;
  promoAdsEnabled!: boolean;
  signUpBackgroundImage!: string;
  signUpLogo!: string;
  type!: CampaignType;
  useGoogleAds!: boolean;
  useFacebookAds!: boolean;
  userDevice!: string;
  landingPageLinkType!: CampaignLandingPageLinkType;
  campaignBudgetType!: CampaignBudgetType;
  adActionType!: AdActionType;
  isUsingFacebook!: boolean;
  isUsingInstagram!: boolean;
  isUsingTwitter!: boolean;
  isUsingGoogleAds!: boolean;
  isUsingLinkedIn!: boolean;

  get hasBrandDefinedLandingPageLink(): boolean {
    return [
      CampaignLandingPageLinkType.CreatedLandingPage,
      CampaignLandingPageLinkType.OneSingleLink,
      CampaignLandingPageLinkType.BrandProvidedPartnerUrl,
    ].includes(this.landingPageLinkType);
  }

  get hasLandingPageRetailerOwnLink(): boolean {
    return (
      this.landingPageLinkType === CampaignLandingPageLinkType.RetailerOwnLink
    );
  }

  get hasFinished(): boolean {
    return dayjs(this.endDate).endOf("day") < dayjs();
  }

  get isFuture(): boolean {
    return dayjs(this.startDate).startOf("day") > dayjs();
  }

  get isOngoing(): boolean {
    return !this.isFuture && !this.hasFinished;
  }

  get isDownloadOnly(): boolean {
    return this.type === CampaignType.DownloadOnly;
  }

  get isBeta(): boolean {
    return this.type === CampaignType.Beta;
  }
}
