import { NgClass } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { PanoViewer } from "@egjs/view360";

@Component({
  selector: "app-panoramic-viewer",
  templateUrl: "./panoramic-viewer.component.html",
  styleUrls: ["./panoramic-viewer.component.scss"],
  standalone: true,
  imports: [NgClass],
})
export class PanoramicViewerComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) public imageUrl!: string;
  @Input() public isThumbnail = false;

  @ViewChild("container", { static: true })
  protected readonly container!: ElementRef<HTMLDivElement>;

  private panoviewer?: PanoViewer;

  public ngAfterViewInit(): void {
    this.panoviewer = new PanoViewer(this.container.nativeElement, {
      image: this.imageUrl,
    });
  }

  public ngOnDestroy(): void {
    this.panoviewer?.destroy();
  }
}
