import dayjs from "dayjs";
import { Mapper } from "../../../../../harmony/core";
import { Ad } from "../../domain/models/ad";
import { AdMetrics } from "../../domain/models/ad-metrics";
import { AdMetricsEntity } from "../entities/ad-metrics.entity";
import { AdEntity } from "../entities/ad.entity";

export class AdEntityToAdMapper implements Mapper<AdEntity, Ad> {
  constructor(
    private readonly adMetricsMapper: Mapper<AdMetricsEntity, AdMetrics>,
  ) {}

  public map(from: AdEntity): Ad {
    return new Ad(
      from.id,
      from.addressId,
      from.budget,
      from.campaignId,
      from.distanceUnit,
      from.facebookAdId,
      from.facebookSetId,
      dayjs(from.fbCampaignStartDate).toDate(),
      dayjs(from.fbCampaignEndDate).toDate(),
      from.gender,
      from.latitude,
      from.longitude,
      from.maxAge,
      from.metrics ? this.adMetricsMapper.map(from.metrics) : undefined,
      from.minAge,
      from.partnerId,
      from.postLogId,
      from.postedToPlatformAt,
      from.radius,
      from.userDevice,
      from.paymentId,
      from.adDuration,
    );
  }
}
