import { Injectable } from "@angular/core";
import { StorageKeys, StorageWrapper } from "./local-storage.service";
import { safeLocalStorage, safeSessionStorage } from "../utils/safe-storage";

@Injectable()
export class SessionStorageService extends StorageWrapper {
  public store(key: StorageKeys, value: string): void {
    safeSessionStorage.setItem(key, value);
  }

  public get(key: StorageKeys): string | null {
    return safeSessionStorage.getItem(key);
  }

  public getWithFallback(key: StorageKeys): string | null {
    const value = safeSessionStorage.getItem(key);

    if (value === null) {
      return safeLocalStorage.getItem(key);
    }

    return value;
  }

  public remove(key: StorageKeys): void {
    safeSessionStorage.removeItem(key);
  }

  public clearAll(): void {
    safeSessionStorage.clear();
  }
}
