import { Component, Input } from "@angular/core";
import { BrandFacebookPostLog } from "../../../models/brandFacebookPostLog";
import { CampaignPostContentType } from "../../../enums/campaignPost.enums";
import { BrandCampaignFacebookCalendarPost } from "../../../models/brandCampaignCalendarPost";
import { PartnerCampaignFacebookPost } from "../../../models/partnerCampaignFacebookPost";
import { Partner } from "../../../models/partner";
import { PartnerCampaignCalendarFacebookPost } from "../../../models/partnerCampaignCalendarFacebookPost";
import { PartnerFacebookPostLog } from "../../../models/partnerFacebookPostLog";

interface InstagramData {
  accountName?: string;
  accountPicture?: string;
}

@Component({
  selector: "app-instagram-post-mock-up",
  templateUrl: "./instagram-post-mock-up.component.html",
  styleUrls: ["./instagram-post-mock-up.component.scss"],
})
export class InstagramPostMockUpComponent {
  @Input() partner?: Partner;
  @Input({ required: true }) post!:
    | PartnerCampaignFacebookPost
    | PartnerFacebookPostLog
    | BrandFacebookPostLog
    | BrandCampaignFacebookCalendarPost
    | PartnerCampaignCalendarFacebookPost;
  public readonly ContentType = CampaignPostContentType;

  public get hasInstagramAccount(): boolean {
    return !!this.getInstagramData();
  }

  public get instagramAccountPicture(): string | undefined {
    return this.getInstagramData()?.accountPicture;
  }

  public get instagramAccountName(): string | undefined {
    return this.getInstagramData()?.accountName;
  }

  private getInstagramData(): InstagramData | undefined {
    if (this.isPostWithInstagramData(this.post)) {
      return {
        accountName: this.post.instagramAccountName,
        accountPicture: this.post.instagramAccountPicture,
      };
    } else if (this.partner?.isConnectedToInstagram) {
      return {
        accountName: this.partner.instagramAccountName ?? undefined,
        accountPicture: this.partner.instagramAccountPicture ?? undefined,
      };
    }

    return undefined;
  }

  private isPostWithInstagramData(
    post: unknown,
  ): post is PartnerFacebookPostLog | BrandFacebookPostLog {
    return (
      (post instanceof PartnerFacebookPostLog ||
        post instanceof BrandFacebookPostLog) &&
      !!post.instagramAccountName &&
      !!post.instagramAccountPicture
    );
  }
}
