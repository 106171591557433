<h2 mat-dialog-title>
  <button
    class="btn btn--content-only btn--back"
    tabindex="-1"
    [mat-dialog-close]=""
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span *ngIf="data.post.isFacebookPost">{{
    "shared.facebookPostPreview" | translate
  }}</span>
  <span *ngIf="data.post.isGoogleAd">{{
    "shared.googleAdPreview" | translate
  }}</span>
  <span *ngIf="data.post.isLinkedInPost">{{
    "linkedin.mockup.preview" | translate
  }}</span>
</h2>
<mat-dialog-content>
  <app-generic-post-mock-up [post]="data.post" [partner]="data.partner" />
</mat-dialog-content>
