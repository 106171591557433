import { PostPlatform } from "../enums/campaignPost.enums";
import { PartnerCampaignPost } from "./partnerCampaignPost";
import { ImageEntity } from "../entities/file/image.entity";
import { AnyToImageEntityMapper } from "../mappers/image.entity.mapper";

export class PartnerCampaignLinkedInPost extends PartnerCampaignPost {
  images!: ImageEntity[];
  link!: string;
  text!: string;
  videoId?: string;
  videoFileId!: number | null;
  videoName?: string;
  videoUrl?: string;
  platform = PostPlatform.LinkedIn;
  linkedInOrganizationName!: string | null;
  linkedInOrganizationLogo!: string | null;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "images":
        return propertyValue.map(AnyToImageEntityMapper);
      default:
        return super.coercePropertyType(propertyName, propertyValue);
    }
  }
}
