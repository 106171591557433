<div
  class="post"
  [ngClass]="{
    'fb-post--carousel': post.mediaType === 'CAROUSEL'
  }"
  *ngIf="post"
>
  <div class="post__header">
    <img
      class="post__avatar"
      src="{{ linkedInOrganizationLogo }}"
      loading="lazy"
    />
    <div
      class="post__user-metadata"
      *ngIf="linkedInOrganizationName; else postMetadataMockup"
    >
      <span class="post__name text--caption">{{
        linkedInOrganizationName
      }}</span>
    </div>
    <ng-template #postMetadataMockup>
      <div class="post__user-metadata post__user-metadata--mockup">
        <div class="post__name"></div>
        <div class="post__description"></div>
      </div>
    </ng-template>
  </div>
  <div class="post__text" [innerHTML]="post.text"></div>
  <div class="post__content">
    <app-linkedin-multi-image-mock-up
      *ngIf="
        post.mediaType === ContentType.Image ||
        post.mediaType === ContentType.MultiImage
      "
      [post]="post"
    ></app-linkedin-multi-image-mock-up>
    <!-- Carousel Type ready to be added as mediaType -->
    <app-carousel *ngIf="post.mediaType === false">
      <div
        carouselItem
        *ngFor="let item of post.images"
        [image]="item.url"
        [text]="item.title"
        [videoUrl]="item.videoUrl"
        [link]="post.link"
      ></div>
    </app-carousel>
    <video
      *ngIf="post.mediaType === ContentType.Video"
      src="{{ post.videoUrl }}"
      preload="metadata"
      controls
    ></video>
  </div>
  <div class="post__reactions">
    <div class="post__likes">
      <img
        class="post__reaction"
        src="/assets/images/icons/linkedin/ic_linkedin_reaction1.svg"
      />
      <img
        class="post__reaction"
        src="/assets/images/icons/linkedin/ic_linkedin_reaction2.svg"
      />
      <img
        class="post__reaction"
        src="/assets/images/icons/linkedin/ic_linkedin_reaction3.svg"
      />
      <img
        class="post__reaction"
        src="/assets/images/icons/linkedin/ic_linkedin_reaction4.svg"
      />
      &nbsp;<span>34</span>
    </div>
    <span class="post__comments">
      {{ "linkedin.mockup.comments" | translate }}
    </span>
  </div>
  <div class="post__footer">
    <div class="post__action">
      <img src="/assets/images/icons/linkedin/ic_linkedin_like.svg" alt="" />
      <span>{{ "shared.like" | translate }}</span>
    </div>
    <div class="post__action">
      <img src="/assets/images/icons/linkedin/ic_linkedin_comment.svg" alt="" />
      <span>{{ "shared.comment" | translate }}</span>
    </div>
    <div class="post__action">
      <img src="/assets/images/icons/linkedin/ic_linkedin_share.svg" alt="" />
      <span>{{ "shared.share" | translate }}</span>
    </div>
    <div class="post__action">
      <img src="/assets/images/icons/linkedin/ic_linkedin_send.svg" alt="" />
      <span>{{ "shared.send" | translate }}</span>
    </div>
  </div>
</div>
