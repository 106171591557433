import { Injectable } from "@angular/core";
import {
  StorageKeys,
  LocalStorageService,
  StorageUserItem,
} from "../local-storage.service";

@Injectable()
export class AuthTokenService {
  constructor(private readonly localStorageService: LocalStorageService) {}

  private getUser(): StorageUserItem | undefined {
    // TODO: We should check that what we're storing is actually of type `StorageUserItem`, see also `AuthenticationService.getUser`
    return this.localStorageService.getParsed<StorageUserItem>(
      StorageKeys.User,
    );
  }

  public set userToken(token: string | undefined) {
    if (!token) {
      this.localStorageService.remove(StorageKeys.User);
      return;
    }

    this.localStorageService.store(
      StorageKeys.User,
      JSON.stringify({
        ...this.getUser(),
        token: token,
      }),
    );
  }

  public get userToken(): string | undefined {
    return this.getUser()?.token;
  }
}
