import dayjs from "dayjs";

import { AdMetricsEntityToAdMetricsMapper } from "../../features/ad/data/mappers/ad-metrics.mapper";
import { AdEntityToAdMapper } from "../../features/ad/data/mappers/ad.mapper";
import {
  BrandAddressEntityToBrandAddressMapper,
  BrandEntityToBrandMapper,
} from "../../features/brand/data/mappers/brand.mapper";
import { Ad } from "../../features/ad/domain/models/ad";
import { Address, AddressType } from "./address";
import { UrlPrefix } from "./appData";
import { Campaign } from "./campaign";
import {
  CampaignBudgetType,
  CampaignPartnerStatus,
  CampaignRequiredAction,
} from "../enums/campaign.enums";
import { PostPlatform } from "../enums/campaignPost.enums";
import { PartnerCampaignCalendar } from "./partnerCampaignCalendar";
import { PartnerCampaignDetails } from "./partnerCampaignDetail";
import { PartnerFacebookPostLog } from "./partnerFacebookPostLog";
import { PartnerGoogleAdPostLog } from "./partnerGoogleAdPostLog";
import { PartnerLinkedInPostLog } from "./partnerLinkedInPostLog";
import { PostLog } from "./postLog";
import { SerializableObject } from "./serializable-object";
import { safeLocalStorage } from "../utils/safe-storage";

const currentCampaignLanguageKey = "currentPartnerCampaignLanguage-";
const currentCampaignCalendarKey = "currentPartnerCampaignCalendarId-";

export class PartnerCampaign extends Campaign {
  effectiveAdsEndDate!: Date;
  effectiveAdsStartDate!: Date;
  campaignPartnerStatus!: CampaignPartnerStatus;
  canPartnerLeave!: boolean;
  hasPartnerApprovedContentCalendar!: boolean;
  partner!: CampaignPartner;
  numberOfParticipants!: number;
  numberOfAdsCreated!: number;
  details!: PartnerCampaignDetails[];
  posts!: PostLog[];
  ads!: Ad[];
  preApproved!: boolean;
  requiredPartnerAction!: CampaignRequiredAction;
  totalPublishedPostLogs!: number;
  totalScheduledPostLogs!: number;
  totalPublishedAds!: number;
  totalScheduledAds!: number;
  adTargetRadius!: number;
  adTaxPercentage!: number;
  managementFee!: number;
  budgetAllocated!: number;
  budgetLeft!: number;
  totalPaidBudget!: number;
  totalPaidAds!: number;
  totalNonPaidAds!: number;
  publisherPlatformAudienceNetwork!: boolean;
  publisherPlatformFacebook!: boolean;
  publisherPlatformInstagram!: boolean;
  hasDefinedCustomBudget!: boolean;
  customBudgetAmount!: number;
  customAdDuration!: number;
  customNumberOfAds!: number;
  canDefineCustomBudget!: boolean;
  totalPartnerAvailableBudget!: number;
  budgetAvailableUntilDate!: Date | null;
  totalBudgetSpent!: number;
  isContentCalendarBeingGenerated!: boolean;

  /**
   * We created this three new properties to show the correct budgets on frontend.
   * It's possible that we had already the same numbers in other variables,
   * but I preferred create a new one with better naming.
   *
   * @link https://trello.com/c/UjKJ4X4q/2433-6-display-error-budget-incl-partner-paid-ad
   */
  budgetCampaignFromBrand!: number;
  budgetScheduledFromBrand!: number;
  budgetLeftFromBrand!: number;
  budgetPaidByPartner!: number;

  get canPromoteMoreAds(): boolean {
    return (
      this.promoAdsEnabled &&
      this.numberOfAdsCreated < this.maxAdsCampaignPartner
    );
  }

  get maximumNumberOfAds(): number {
    return this.hasDefinedCustomBudget
      ? this.customNumberOfAds
      : this.maxAdsCampaignPartner;
  }

  get standardAdDuration(): number {
    return this.hasDefinedCustomBudget
      ? this.customAdDuration
      : this.adDuration;
  }

  get canPartnerEditBudget(): boolean {
    return (
      this.campaignBudgetType === CampaignBudgetType.TimeFrame &&
      this.canDefineCustomBudget
    );
  }

  get isPartnerPendingToDefineACustomCampaignBudget(): boolean {
    return (
      this.campaignBudgetType === CampaignBudgetType.TimeFrame &&
      !this.hasDefinedCustomBudget
    );
  }

  get hasReachedAdLimit(): boolean {
    return this.numberOfAdsCreated >= this.maximumNumberOfAds;
  }

  get partnerHasAccess(): boolean {
    return (
      this.campaignPartnerStatus &&
      (this.campaignPartnerStatus === CampaignPartnerStatus.Validated ||
        this.campaignPartnerStatus === CampaignPartnerStatus.Engaged)
    );
  }

  get partnerDefaultAddress(): Address {
    let defaultAddresses: Address[] = [];
    if (this.partner && this.partner.addresses) {
      defaultAddresses = this.partner.addresses.filter(
        (address) =>
          address.type === AddressType.Shipping && address.isDefault === true,
      );
    }
    if (defaultAddresses[0]) {
      return defaultAddresses[0];
    }
    // If by some error there is no default shipping address we use the
    // first one that we find
    defaultAddresses = this.partner.addresses.filter(
      (address: Address) => address.type === AddressType.Shipping,
    );
    return defaultAddresses[0] ? defaultAddresses[0] : (null as any);
  }

  get currentLanguage(): string {
    // get list of languages for populating the language selector
    const languages = this.details.map((item) => item.language);
    if (safeLocalStorage.getItem(currentCampaignLanguageKey + this.id)) {
      const lang = languages.filter(
        (l) =>
          l.locale ===
          safeLocalStorage.getItem(currentCampaignLanguageKey + this.id),
      );
      if (lang.length > 0) {
        // maintain previously selected language
        return safeLocalStorage.getItem(currentCampaignLanguageKey + this.id)!;
      }
    }
    return this.details[0].language.locale;
  }

  get currentDetails(): PartnerCampaignDetails | undefined {
    const currentDetails = this.details.filter(
      (d) => d.language.locale === this.currentLanguage,
    );
    if (currentDetails.length > 0) {
      // maintain previously selected language
      return currentDetails[0];
    } else {
      // set first 'details' object as the current details
      return this.details[0];
    }
  }

  get currentCalendar(): PartnerCampaignCalendar | null {
    const currentCampaignCalendarId = safeLocalStorage.getItem(
      currentCampaignCalendarKey + this.currentDetails!.id,
    );
    if (currentCampaignCalendarId) {
      const selectedCalendarArray = this.currentDetails!.calendars.filter(
        (calendar: PartnerCampaignCalendar) =>
          calendar.id === Number(currentCampaignCalendarId),
      );
      if (selectedCalendarArray[0]) {
        // maintain previously selected calendar
        return selectedCalendarArray[0];
      } else if (this.currentDetails!.calendars[0]) {
        // set calendar of the first calendar object as the current language
        return this.currentDetails!.calendars[0];
      }
    } else if (this.currentDetails!.calendars[0]) {
      // set calendar of the first calendar object as the current language
      return this.currentDetails!.calendars[0];
    }
    // No calendars in this details (could happen)
    return null;
  }

  get budgetPerAd(): number {
    if (this.hasDefinedCustomBudget) {
      return +(this.customBudgetAmount / this.customNumberOfAds).toFixed(2);
    }
    return +(this.budget / this.maxAdsCampaignPartner).toFixed(2);
  }

  get totalCampaignBudget(): number {
    if (this.hasDefinedCustomBudget) {
      return this.customBudgetAmount;
    }
    if (
      this.campaignBudgetType === CampaignBudgetType.TimeFrame &&
      this.budget > this.totalPartnerAvailableBudget
    ) {
      return this.totalPartnerAvailableBudget;
    }
    return this.budget;
  }

  changeLanguage(locale: string) {
    const details = this.details.filter((d) => d.language.locale === locale);

    if (details.length > 0) {
      safeLocalStorage.setItem(
        currentCampaignLanguageKey + this.id,
        details[0].language.locale,
      );
    }
  }

  changeCampaignCalendar(calendar: PartnerCampaignCalendar) {
    const selectedCalendarArray = this.currentDetails!.calendars.filter(
      (filterCalendar: PartnerCampaignCalendar) =>
        filterCalendar.id === calendar.id,
    );
    if (selectedCalendarArray[0]) {
      safeLocalStorage.setItem(
        currentCampaignCalendarKey + this.currentDetails!.id,
        calendar.id.toString(),
      );
    }
  }

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "effectiveAdsEndDate":
        return dayjs(propertyValue).toDate();
      case "effectiveAdsStartDate":
        return dayjs(propertyValue).toDate();
      case "startDate":
        return dayjs(propertyValue).toDate();
      case "endDate":
        return dayjs(propertyValue).toDate();
      case "fbCampaignStartDate":
        return dayjs(propertyValue).toDate();
      case "fbCampaignEndDate":
        return dayjs(propertyValue).toDate();
      case "budgetAvailableUntilDate":
        return propertyValue ? dayjs(propertyValue).toDate() : null;
      case "partner":
        return new CampaignPartner(propertyValue);
      case "ads":
        const adMetricsMapper = new AdMetricsEntityToAdMetricsMapper();
        const adMapper = new AdEntityToAdMapper(adMetricsMapper);
        return propertyValue.map((ad: any) => adMapper.map(ad));
      case "brand":
        const brandMapper = new BrandEntityToBrandMapper(
          new BrandAddressEntityToBrandAddressMapper(),
        );
        return brandMapper.map(propertyValue);
      case "details":
        return propertyValue.map(
          (details: any) => new PartnerCampaignDetails(details),
        );
      case "posts":
        return propertyValue.map((postData: any) => {
          if (postData.platform === PostPlatform.Facebook) {
            return new PartnerFacebookPostLog(postData);
          } else if (postData.platform === PostPlatform.Google) {
            return new PartnerGoogleAdPostLog(postData);
          } else if (postData.platform === PostPlatform.LinkedIn) {
            return new PartnerLinkedInPostLog(postData);
          }
        });
      default:
        return propertyValue;
    }
  }
}

export enum PartnerCampaignStatus {
  All = "all",
  Upcoming = "upcoming",
  Active = "active",
  Past = "past",
}

export const PartnerCampaignStatusAttrs = {
  [PartnerCampaignStatus.Upcoming]: {
    value: 2,
    label: "shared.brandCampaignStatus.upcoming",
    icon: "ic_upcoming",
  },
  [PartnerCampaignStatus.Active]: {
    value: 3,
    label: "shared.brandCampaignStatus.inProgress",
    icon: "ic_inProgress",
  },
  [PartnerCampaignStatus.Past]: {
    value: 4,
    label: "shared.brandCampaignStatus.finished",
    icon: "ic_finished",
  },
};

export enum PreApprovalSource {
  Campaign = "CAMPAIGN",
  Brand = "BRAND",
  All = "ALL",
}

export class CampaignPartner extends SerializableObject {
  id!: number;
  // address!: Address;
  enabled!: boolean;
  logo!: string;
  salesRep!: string;
  shortUrl!: string;
  shortUrlPrefix!: UrlPrefix;
  status!: CampaignPartnerStatus;
  postsAdditionalText!: string;
  companyName!: string;
  customFieldLabel!: string;
  customFieldValue!: string;
  isCustomFieldEnabled!: boolean;
  addresses!: Address[];
  defaultAddressId!: number;
  email!: string;
  isProspect!: boolean;

  get isShortUrlMissing(): boolean {
    return !this.shortUrl;
  }
  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "addresses":
        return propertyValue.map((address: any) => new Address(address));
      default:
        return propertyValue;
    }
  }
}
