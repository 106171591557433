export enum ByteUnit {
  Byte,
  KB,
  MB,
  GB,
}

/* eslint-disable-next-line no-redeclare */
export namespace ByteUnit {
  export const convert = (
    value: number,
    fromUnit: ByteUnit,
    toUnit: ByteUnit = ByteUnit.Byte,
  ): number => value * Math.pow(1024, fromUnit - toUnit);

  export const makingBytesReadable = (
    amountOfBytes: number,
    amountOfDecimals = 0,
  ): string => {
    if (amountOfBytes === 0) {
      return "0 Bytes";
    }
    const OneKB = 1024;
    const TranslatedSizes = [
      "Bytes",
      "KB",
      "MB",
      "GB",
      "TB",
      "PB",
      "EB",
      "ZB",
      "YB",
    ];
    const Index = Math.floor(Math.log(amountOfBytes) / Math.log(OneKB));
    return `${parseFloat(
      (amountOfBytes / Math.pow(OneKB, Index)).toFixed(amountOfDecimals),
    )}${TranslatedSizes[Index]}`;
  };
}
