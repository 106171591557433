import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import dayjs from "dayjs";

import { LanguageService } from "../services/language.service";

@Pipe({
  name: "localizeDate",
  pure: false,
})
export class LocalizeDatePipe implements PipeTransform {
  constructor(private readonly languageService: LanguageService) {}

  public transform(value: any, pattern = "mediumDate"): string | null {
    const dayjsUtc = dayjs(value).utc();
    const utcDate = new Date(
      dayjsUtc.get("year"),
      dayjsUtc.get("month"),
      dayjsUtc.get("date"),
      dayjsUtc.get("hour"),
      dayjsUtc.get("minute"),
      dayjsUtc.get("second"),
    );

    return new DatePipe(this.languageService.locale).transform(
      utcDate,
      pattern,
    );
  }
}
