import { SerializableObject } from "./serializable-object";

export class CampaignProduct extends SerializableObject {
  id!: number;
  title!: string;
  description!: string;
  options!: ProductOption[];
  images!: ProductImage[];

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "options":
        return propertyValue.map((option: any) => new ProductOption(option));
      case "images":
        return propertyValue.map((image: any) => new ProductImage(image));
      default:
        return propertyValue;
    }
  }
}

export class ProductOption extends SerializableObject {
  id!: number;
  text!: string;
  price!: string;
}

export class ProductImage extends SerializableObject {
  id!: number;
  file!: string;
  fileType!: string;
  name!: string;
}
