import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
} from "@angular/core";

import {
  UploadOutput,
  UploadInput,
  UploadFile,
  UploaderOptions,
} from "@angular-ex/uploader";
import { ByteUnit } from "../../functions/byte-unit";
import { AuthTokenService } from "../../services/api/auth-token.service";
import { InvalidDimensionsUploaderResponseError } from "./models/invalid-dimensions-uploader-response-error";
import { InvalidMimeTypeUploaderResponseError } from "./models/invalid-mime-type-uploader-response-error";
import { InvalidSizeUploaderResponseError } from "./models/invalid-size-uploader-response-error";
import { UploaderResponseError } from "./models/uploader-response-error";
import { InvalidVideoDurationUploaderResponseError } from "./models/invalid-video-duration-uploader-response-error";
import { InvalidImageAspectRatioResponseError } from "./models/invalid-image-aspect-ratio-response-error";
import { assert } from "../../utils/assert";

const enum UploaderResponseType {
  AllAddedToQueue = "allAddedToQueue",
  AddedToQueue = "addedToQueue",
  Uploading = "uploading",
  Removed = "removed",
  DragOver = "dragOver",
  DragOut = "dragOut",
  Drop = "drop",
  Done = "done",
  Rejected = "rejected",
  Start = "start",
  Cancelled = "cancelled",
  RemovedAll = "removedAll",
}

export enum UploaderHtmlTemplate {
  InputOnly = "inputOnly",
  Default = "default",
  AddIcon = "addIcon",
  AddIconOrange = "addIconOrange",
}

const enum UploaderAction {
  Cancel = "cancel",
  Remove = "remove",
  RemoveAll = "removeAll",
}

export enum UploaderAcceptFormat {
  Image = "image/png,image/jpeg",
  Gif = "image/gif",
  Pdf = "application/pdf",
  VideoMP4 = "video/mp4",
  Excel = "xls,application/vnd.ms-excel,xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // For the TSV files we had to put a comma at the beginning, it was necessary to work correctly in the Windows environment, which for some unspecified reason doesn't recognize the mime type of TSV files
  Tsv = ",tsv,text/tab-separated-values",
  Any = "*/*",
}

export interface FileValidator {
  validate: (file: File) => Promise<boolean>;
}

@Component({
  selector: "app-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploaderComponent<T = unknown>
  implements OnInit, AfterViewInit
{
  @Input({ required: true }) uploadUrl!: string;
  @Input() uploaderTitle?: string;
  @Input() uploaderSubtitle?: string;
  @Input() maxFileSize = ByteUnit.convert(30, ByteUnit.MB, ByteUnit.Byte);
  @Input() maxVideoDurationMinutes?: number;
  @Input() uploadData: any = {};
  @Input() fieldName = "file";
  @Input() autoUpload = true;
  @Input() uploading = false;
  @Input() size?: number;
  @Input() uploadHtmlTemplate = UploaderHtmlTemplate.Default;
  @Input() acceptFormat: UploaderAcceptFormat | UploaderAcceptFormat[] =
    UploaderAcceptFormat.Image;
  @Input() customFileValidators: FileValidator[] = [];
  @Input() responseMapper: (res?: unknown) => T = (res) => res as T;

  @Output() apiResponse = new EventEmitter<T>();
  @Output() fileAddedToQueue = new EventEmitter<UploadFile>();
  @Output() uploadError = new EventEmitter<UploaderResponseError>();

  @ViewChild("fileInput") inputElement?: ElementRef;
  @ViewChild("addButton") addButtonElement?: ElementRef;

  protected readonly UploaderHtmlTemplate = UploaderHtmlTemplate;
  protected options!: UploaderOptions;
  protected files: UploadFile[] = [];
  protected uploadInput = new EventEmitter<UploadInput>();
  protected dragOver = false;
  protected fileUploaderId: string;

  constructor(private readonly authTokenService: AuthTokenService) {
    this.fileUploaderId = (Math.random() + 1).toString(36).substring(7);
  }

  public ngOnInit(): void {
    const isAnyTypeAccepted = this.acceptFormat === UploaderAcceptFormat.Any;

    this.options = {
      concurrency: 1,
      maxFileSize: this.maxFileSize,
      ...(!isAnyTypeAccepted && {
        allowedContentTypes: this.parsedFormats.split(","),
      }),
    };
  }

  public get parsedFormats(): string {
    if (!Array.isArray(this.acceptFormat)) {
      return this.acceptFormat;
    }
    return this.acceptFormat.join(",");
  }

  public ngAfterViewInit(): void {
    if (this.size && this.addButtonElement) {
      this.addButtonElement.nativeElement.style.width = this.size + "px";
      this.addButtonElement.nativeElement.style.height = this.size + "px";
    }

    if (this.inputElement) {
      this.inputElement.nativeElement.onclick = function () {
        this.value = null;
      };
    }

    if (this.uploadError.observers.length === 0) {
      throw new Error("No error handler assigned - FileUploaderComponent");
    }
  }

  public fileInputClick() {
    this.inputElement?.nativeElement.click();
  }

  public onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case UploaderResponseType.AllAddedToQueue:
        if (this.autoUpload) {
          this.startUploadAll();
        }
        break;

      case UploaderResponseType.AddedToQueue:
        const file = output.file;

        assert(file, "File is undefined");
        assert(file.nativeFile, "File.nativeFile is undefined");

        Promise.all(
          this.customFileValidators.map(async (val) =>
            val.validate(file.nativeFile!),
          ),
        ).then(
          () => {
            this.files.push(file);
            this.fileAddedToQueue.emit(file);
          },
          () => {
            this.cancelUpload(file.id);
            this.removeFile(file.id);
            this.uploadError.emit(this.mapError(output));
          },
        );
        break;

      case UploaderResponseType.Uploading:
        if (typeof output.file !== "undefined") {
          // update current data in files array for uploading file
          const index = this.files.findIndex(
            (file) =>
              typeof output.file !== "undefined" && file.id === output.file.id,
          );
          this.files[index] = output.file;
        }
        break;

      case UploaderResponseType.Removed:
        // remove file from array when removed
        this.files = this.files.filter((file) => file !== output.file);
        break;
      case UploaderResponseType.DragOver:
        this.dragOver = true;
        break;

      case UploaderResponseType.DragOut:
      case UploaderResponseType.Drop:
        this.dragOver = false;
        break;

      case UploaderResponseType.Done:
        this.files = this.files.filter((file) => file !== output.file);
        if (!output.file?.response) {
          this.uploadError.emit(new UploaderResponseError());
          break;
        }

        if (output.file?.response?.error) {
          this.uploadError.emit(this.mapError(output));
          return;
        }

        try {
          this.apiResponse.emit(this.responseMapper(output.file.response));
        } catch {
          this.uploadError.emit(new UploaderResponseError());
        }
        break;

      case UploaderResponseType.Rejected:
        this.uploadError.emit(this.mapError(output));
        break;
    }
  }

  public startUploadFile(): void {
    if (this.files.length > 0) {
      this.uploading = true;
      const event: UploadInput = {
        type: "uploadFile",
        file: this.files[0],
        ...this.commonUploadConfig,
      };
      this.uploadInput.emit(event);
    }
  }

  public startUploadAll(): void {
    this.uploading = true;
    const event: UploadInput = {
      type: "uploadAll",
      ...this.commonUploadConfig,
    };
    this.uploadInput.emit(event);
  }

  private get commonUploadConfig(): Partial<UploadInput> {
    return {
      method: "POST",
      data: this.uploadData,
      fieldName: this.fieldName,
      url: this.uploadUrl,
      headers: {
        Authorization: "Bearer " + this.authTokenService.userToken,
      },
    };
  }

  public cancelUpload(id: string): void {
    this.uploadInput.emit({ type: UploaderAction.Cancel, id: id });
  }

  public removeFile(id: string): void {
    this.uploadInput.emit({ type: UploaderAction.Remove, id: id });
  }

  public removeAllFiles(): void {
    this.uploadInput.emit({ type: UploaderAction.RemoveAll });
  }

  private mapError(output: UploadOutput): UploaderResponseError {
    if (!output.file) {
      return new UploaderResponseError();
    }

    if (
      [
        "INVALID_DIMENSIONS",
        "INVALID_GOOGLE_AD_FILE_DIMENSIONS",
        "FACEBOOK_IMAGE_DIMENSIONS_INVALID",
      ].includes(output.file.response?.error?.key)
    ) {
      return new InvalidDimensionsUploaderResponseError();
    }

    if (
      ["FACEBOOK_IMAGE_ASPECT_RATIO_INVALID"].includes(
        output.file.response?.error?.key,
      )
    ) {
      return new InvalidImageAspectRatioResponseError();
    }

    this.files = this.files.filter((file) => file !== output.file);

    if (
      this.options.allowedContentTypes &&
      !this.options.allowedContentTypes?.includes(output.file.type)
    ) {
      return new InvalidMimeTypeUploaderResponseError();
    }

    if (
      this.options.maxFileSize &&
      output.file.size > this.options.maxFileSize
    ) {
      return new InvalidSizeUploaderResponseError(this.options.maxFileSize);
    }

    if (
      this.maxVideoDurationMinutes &&
      output.file.response?.error?.key === "FACEBOOK_VIDEO_DURATION_INVALID"
    ) {
      return new InvalidVideoDurationUploaderResponseError(
        this.maxVideoDurationMinutes,
      );
    }

    return new UploaderResponseError();
  }
}
