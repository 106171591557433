import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";
import { Type } from "../../../harmony/core";

/**
 * Infer signature based on Input/Output values
 */
// prettier-ignore
type DialogFn<Input, Output> = Input extends undefined
  ? Output extends undefined
    ? () => Promise<undefined>
    : () => Promise<Output | undefined>
  : Output extends undefined
    ? (data: Input) => Promise<undefined>
    : (data: Input) => Promise<Output | undefined>;

@Injectable()
export class CommonDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Open a Material Dialog with extra type safety
   *
   * This function forces to define the input/output types of the dialog:
   * - Input data type
   * - Result data type
   *
   * This is specially interesting in strict mode because the default generic for a dialog return type is `any`.
   * So when using `afterClosed`, it returns `any | undefined`, which TS resolves as `any` (hence we loose type safety).
   * By forcing an `Output` type we ensure we'll get `T | undefined` and we'll be forced to handle the `undefined` case in the components.
   *
   * @template Input Input data for the dialog.
   * @template Output Resulting output data type of the dialog. Note that the dialog will return `Output | undefined`.
   * @param component Dialog class to open.
   * @param config MatDialog configuration, same as in regular `MatDialog.open`
   */
  public openDialog<Input, Output>(
    component: Type<unknown>,
    config: MatDialogConfig<Input> = {},
  ): Promise<Output | undefined> {
    const dialogRef = this.dialog.open(component, {
      panelClass: ["sp-dialog"],
      ...config,
    });

    return lastValueFrom(dialogRef.afterClosed());
  }

  /**
   * Factory for dialog service methods
   *
   * This functions returns a functions that can be assigned to a class
   * public properties (so they work as methods). This removes most of
   * the boilerplate from dialog generics.
   *
   * @param component Dialog class to open.
   * @param config MatDialog configuration, same as in regular `MatDialog.open`. `data` will get injected per call.
   */
  public createOpenDialogFn<Input, Output>(
    component: Type<unknown>,
    config:
      | MatDialogConfig<Input>
      | ((data: Input) => MatDialogConfig<Input>) = {},
  ): DialogFn<Input, Output> {
    return ((data: Input) => {
      if (typeof config === "function") {
        config = config(data);
      }

      return this.openDialog<Input, Output>(component, {
        ...config,
        data: data,
      });
    }) as unknown as DialogFn<Input, Output>;
  }
}
