import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { lastValueFrom, Observable } from "rxjs";
import {
  PostPreviewDialogComponent,
  PostPreviewDialogData,
  PostPreviewDialogResult,
} from "../components/post-preview-dialog/post-preview-dialog.component";
import {
  AlertDialogComponent,
  AlertDialogData,
  AlertDialogResult,
} from "../components/alert-dialog/alert-dialog.component";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogResult,
} from "../components/confirmation-dialog/confirmation-dialog.component";
import {
  ErrorDialogComponent,
  ErrorDialogData,
  ErrorDialogResult,
} from "../components/error-dialog/error-dialog.component";
import { CommonDialogService } from "./common-dialog.service";

export type ConfirmationData = ConfirmationDialogData & {
  onConfirm?: () => void;
};

@Injectable()
export class DialogService {
  constructor(
    private dialog: CommonDialogService,
    private matDialog: MatDialog,
  ) {}

  public closeAll(): void {
    this.matDialog.closeAll();
  }

  public async confirm(data: ConfirmationData): Promise<boolean> {
    const confirmed = (await lastValueFrom(this.confirm$(data))) ?? false;

    if (confirmed && data.onConfirm && typeof data.onConfirm === "function") {
      data.onConfirm();
    }

    return confirmed;
  }

  private confirm$(
    data: ConfirmationDialogData,
  ): Observable<ConfirmationDialogResult | undefined> {
    const dialogConfig: MatDialogConfig<ConfirmationDialogData> = {
      data: data,
      panelClass: ["sp-dialog"],
    };

    return this.matDialog
      .open<
        ConfirmationDialogComponent,
        ConfirmationDialogData,
        ConfirmationDialogResult
      >(ConfirmationDialogComponent, dialogConfig)
      .afterClosed();
  }

  public readonly alert = this.dialog.createOpenDialogFn<
    AlertDialogData,
    AlertDialogResult
  >(AlertDialogComponent);

  public showConfirmRemoveUser(email: string): Promise<boolean> {
    return this.confirm({
      title: "user.removeDialog.title",
      message: ["user.removeDialog.description", { email: email }],
      confirmButton: "shared.remove",
    });
  }

  public readonly showPostPreview = this.dialog.createOpenDialogFn<
    PostPreviewDialogData,
    PostPreviewDialogResult
  >(PostPreviewDialogComponent, (data) => {
    const panelClass = [
      "sp-post-preview",
      "no-actions-dialog",
      ...(data.post.isLinkedInPost ? ["sp-post-preview--linkedin"] : []),
    ];

    return {
      panelClass: panelClass,
    };
  });

  public readonly showError = this.dialog.createOpenDialogFn<
    ErrorDialogData,
    ErrorDialogResult
  >(ErrorDialogComponent, {
    disableClose: true,
  });
}
