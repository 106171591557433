import { BannerEntity, BannerFileEntity } from "../entities/file/banner.entity";

export const AnyToBannerEntityMapper = (from: any) =>
  new BannerEntity(
    from.id,
    from.files.map(
      (file: any) =>
        new BannerFileEntity(
          file.id,
          file.file,
          file.filePublicId,
          file.fileType,
          file.name,
        ),
    ),
    from.mediaType,
    from.title,
  );
