import { Component, Input, OnChanges } from "@angular/core";
import { PartnerNotSelectedError } from "../../../errors/partner-not-selected-error";
import { PartnerService } from "../../../services/api/partner.service";
import { BrandCampaignLinkedInPost } from "../../../models/brandCampaignLinkedInPost";
import { LinkedInPostContentType } from "../../../enums/campaignPost.enums";
import { PartnerCampaignLinkedInPost } from "../../../models/partnerCampaignLinkedInPost";

@Component({
  selector: "app-linkedin-post-mock-up",
  templateUrl: "./linkedin-post-mock-up.component.html",
  styleUrls: ["./linkedin-post-mock-up.component.scss"],
})
export class LinkedInPostMockUpComponent implements OnChanges {
  @Input() public post!:
    | BrandCampaignLinkedInPost
    | PartnerCampaignLinkedInPost; // TODO: Required Input

  public readonly ContentType = LinkedInPostContentType;
  public linkedInOrganizationName?: string;
  public linkedInOrganizationLogo!: string;

  constructor(private readonly partnerService: PartnerService) {}

  public ngOnChanges(): void {
    try {
      this.linkedInOrganizationName =
        this.post.linkedInOrganizationName ??
        this.partnerService.currentPartner?.companyName;

      this.linkedInOrganizationLogo =
        this.post.linkedInOrganizationLogo ??
        this.partnerService.currentPartner?.squareLogo ??
        "assets/images/icons/linkedin/as_company_placeholder@2x.jpg";
    } catch (error) {
      if (error instanceof PartnerNotSelectedError) {
        this.linkedInOrganizationName = undefined;
        this.linkedInOrganizationLogo =
          "assets/images/icons/linkedin/as_company_placeholder@2x.jpg";
      }
    }
  }
}
