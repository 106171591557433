import { UserPermissionsEntityToUserPermissionsMapper } from "../../features/auth/data/mappers/user-permissions.mapper";
import { UserPermissions } from "../../features/auth/domain/models/user-permissions";
import { SerializableObject } from "./serializable-object";
import { AppLocale } from "./appData";

export enum UserRole {
  Partner = "partner",
  Brand = "brand",
  Admin = "admin",
  ExternalPartnerAdmin = "external_partner_admin",
}

export enum UserStatus {
  ACTIVE = "active",
  INVITED = "invited",
  SUSPENDED = "suspended",
  INCOMPLETE = "registration_incomplete",
}

export class User extends SerializableObject {
  id!: number;
  email!: string;
  locale!: AppLocale;
  password!: string;
  permissions!: UserPermissions;
  role!: UserRole;
  signUpCampaignId!: number;
  status!: UserStatus;
  isVerified!: boolean;
  createdAt!: number;
  intercomUserHash!: string;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "permissions":
        const permissionsMapper =
          new UserPermissionsEntityToUserPermissionsMapper();
        return permissionsMapper.map(propertyValue);
      default:
        return propertyValue;
    }
  }
}
