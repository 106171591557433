export enum ImageRatio {
  Squared = "SQUARED",
  Landscape = "LANDSCAPE",
}

export enum PartnerFacebookPageClaimStatus {
  NotClaimed = "not_claimed",
  Claimed = "claimed",
  Authorized = "authorized",
}

export enum PartnerLogoStatus {
  NoLogo = "no_logo",
  PartnerUploaded = "partner_uploaded",
  AdminUploaded = "admin_uploaded",
}
