import { AdMetrics } from "./ad-metrics";

export class Ad {
  constructor(
    public readonly id: number,
    public readonly addressId: number,
    public readonly budget: number,
    public readonly campaignId: number,
    public readonly distanceUnit: string,
    public readonly facebookAdId: string,
    public readonly facebookSetId: string,
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly gender: string,
    public readonly latitude: number,
    public readonly longitude: number,
    public readonly maxAge: string,
    public readonly metrics: AdMetrics | undefined,
    public readonly minAge: string,
    public readonly partnerId: number,
    public readonly postLogId: number,
    public readonly postedToPlatformAt: string,
    public readonly radius: number,
    public readonly userDevice: string,
    public readonly paymentId?: number,
    public readonly adDuration?: number,
  ) {}

  public get isPaidAd(): boolean {
    return !!this.paymentId;
  }
}
