import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

type TranslationTuple = [key: string, placeholders: Object];

interface Data<T = string | TranslationTuple> {
  title: T;
  message: T;
  details?: T;
  confirmButton?: T;
  cancelButton?: T;
}

export type ConfirmationDialogData = Data;
export type ConfirmationDialogResult = boolean;

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent {
  public readonly data: Data<string>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConfirmationDialogData,
    private readonly translation: TranslateService,
  ) {
    // Normalize to `string`
    this.data = {
      title: this.translate(data.title),
      message: this.translate(data.message),
      details: this.maybeTranslate(data.details),
      confirmButton: this.translate(data.confirmButton ?? "shared.accept"),
      cancelButton: this.translate(data.cancelButton ?? "shared.cancel"),
    };
  }

  private maybeTranslate(
    value?: string | TranslationTuple,
  ): string | undefined {
    return value ? this.translate(value) : undefined;
  }

  private translate(value: string | TranslationTuple): string {
    if (Array.isArray(value)) {
      return this.translation.instant(value[0], value[1]);
    }

    return this.translation.instant(value);
  }
}
