export enum CampaignType {
  Beta = "BETA",
  Contest = "CONTEST",
  ProductTester = "PRODUCT_TESTER",
  DownloadOnly = "ONLY_DOWNLOAD_AREA",
  DownloadOnlyWithShortLink = "ONLY_DOWNLOAD_AREA_WITH_SHORT_LINK",
}

export enum PaymentType {
  Self = "SELF_FUNDED",
  Brand = "BRAND_FUNDED",
  CoFunded = "CO_FUNDED",
}

export enum ObjectiveType {
  LinkClicks = "LINK_CLICKS",
  Conversions = "CONVERSIONS",
  Reach = "REACH",
}

export const CtaText = {
  ADD_TO_CART: "Add To Cart",
  APPLY_NOW: "Apply Now",
  BOOK_TRAVEL: "Book Travel",
  BUY: "Buy",
  BUY_NOW: "Buy Now",
  BUY_TICKETS: "Buy Tickets",
  CALL: "Call",
  CALL_ME: "Call me",
  CONTACT_US: "Contact us",
  DONATE: "Donate",
  DONATE_NOW: "Donate now",
  DOWNLOAD: "Download",
  EVENT_RSVP: "Event RSVP",
  FOLLOW_NEWS_STORYLINE: "Follow News Storyline",
  GET_OFFER: "Get Offer",
  GET_OFFER_VIEW: "Get Offer View",
  GET_QUOTE: "Get Quote",
  GET_SHOWTIMES: "Get Showtimes",
  INSTALL_APP: "Install App",
  INSTALL_MOBILE_APP: "Install mobile app",
  LEARN_MORE: "Learn More",
  LIKE_PAGE: "Link Page",
  LISTEN_MUSIC: "Listen Music",
  LISTEN_NOW: "Listen Now",
  MESSAGE_PAGE: "Message Page",
  MOBILE_DOWNLOAD: "Mobile Download",
  MOMENTS: "Moments",
  NO_BUTTON: "No Button",
  ORDER_NOW: "Order Now",
  PLAY_GAME: "Play Game",
  RECORD_NOW: "Record Now",
  SAY_THANKS: "Say Thanks",
  SELL_NOW: "Sell Now",
  SHARE: "Share",
  SHOP_NOW: "Shop Now",
  SIGN_UP: "Sign Up",
  SUBSCRIBE: "Subscribe",
  UPDATE_APP: "Update App",
  USE_APP: "Use App",
  USE_MOBILE_APP: "Use mobile app",
  VIDEO_ANNOTATION: "Video Annotation",
  VISIT_PAGES_FEED: "Visit Pages Feed",
  WATCH_MORE: "Watch More",
  WATCH_VIDEO: "Watch Video",
  WHATSAPP_MESSAGE: "WhatsApp Message",
  OPEN_LINK: "Open Link",
};

export type CtaTextType = keyof typeof CtaText;

export enum CampaignContentType {
  Post = "POST", // Facebook
  GoogleAd = "GOOGLE_AD",
  LinkedinPost = "LINKEDIN_POST",
  Banner = "MARKETING_IMAGE",
  Video = "VIDEO",
}

export enum CampaignBannerMediaType {
  FacebookHeader = "FACEBOOK_HEADER",
  InstagramPost = "INSTAGRAM_POST",
  TwitterHeader = "TWITTER_HEADER",
  Banner = "BANNER",
}

export enum CampaignRequiredAction {
  AddConnection = "ADD_CONNECTION",
  AcceptInvitation = "ACCEPT_INVITATION",
  BudgetLeft = "BUDGET_LEFT",
  ShowResults = "SHOW_RESULTS",
  StartCampaign = "START_CAMPAIGN",
}

export enum SortByDateMode {
  Asc = "asc",
  Desc = "desc",
}

export enum Gender {
  Both = "both",
  Female = "female",
  Male = "male",
}

export enum CampaignDatesType {
  SameDates = "SAME_DATES",
  CustomDates = "CUSTOM_DATES",
}

export enum AdActionType {
  Boost = "boost",
  Link = "link",
}

export enum GoogleAdsBiddingStrategyType {
  TargetSpend = "TARGET_SPEND",
  ManualCPC = "MANUAL_CPC",
  ManualCPM = "MANUAL_CPM",
}

export const GoogleAdsBiddingStrategyName = {
  MANUAL_CPC: "Manual CPC",
  MANUAL_CPM: "Manual CPM",
  PAGE_ONE_PROMOTED: "Page One Promoted",
  TARGET_SPEND: "Target Spend",
  TARGET_CPA: "Target CPA",
  TARGET_ROAS: "Target Roas",
  MAXIMIZE_CONVERSIONS: "Maximize Conversions",
  MAXIMIZE_CONVERSION_VALUE: "Maximize conversion value",
  TARGET_OUTRANK_SHARE: "Target Outrank Share",
};

export enum CampaignCurrency {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  SEK = "SEK",
  CHF = "CHF",
}

export enum CampaignCurrencySymbol {
  USD = "$",
  EUR = "€",
  GBP = "£",
  SEK = "SEK",
  CHF = "CHF",
}

export enum CampaignBudgetType {
  PerCampaign = "PER_CAMPAIGN_BUDGET",
  TimeFrame = "TIME_FRAME_BUDGET",
}

export enum CampaignPartnerStatus {
  WaitingToBeInvited = "WAITING_TO_BE_INVITED",
  Invited = "INVITED",
  Cancelled = "CANCELLED",
  Accepted = "ACCEPTED",
  Engaged = "ENGAGED",
  PendingValidation = "PENDING_VALIDATION",
  Validated = "VALIDATED",
  JoinLinkSent = "JOIN_LINK_SENT",
  SendLinkToJoin = "SEND_LINK_TO_JOIN",
}

export enum PostListSidePanelType {
  Stats = "stats",
  AdForm = "ad_form",
}

export enum CampaignLandingPageLinkType {
  IndividualLandingPage = "INDIVIDUAL_LANDING_PAGE",
  RetailerOwnLink = "RETAILER_OWN_LINK",
  OneSingleLink = "ONE_SINGLE_LINK",
  CreatedLandingPage = "CREATED_LANDING_PAGE",
  BrandProvidedPartnerUrl = "PROVIDED_BY_BRAND_PER_PARTNER",
}
