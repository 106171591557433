import dayjs from "dayjs";

import { CampaignPost } from "./campaignPost";
import {
  CampaignPostContentType,
  PostPlatform,
  PostTargetPlatforms,
} from "../enums/campaignPost.enums";
import { ImageEntity } from "../entities/file/image.entity";
import { FacebookPost } from "../types/campaignPost.types";
import { AnyToImageEntityMapper } from "../mappers/image.entity.mapper";

export type BrandCalendarPost =
  | BrandCampaignFacebookCalendarPost
  | BrandCampaignGoogleCalendarPost
  | BrandCampaignLinkedInCalendarPost;

export abstract class BrandCampaignCalendarPost extends CampaignPost {
  image!: string;
  suggestedPublishDate!: Date;
  suggestedPublishDateTimezone!: string;
  isDarkPost!: boolean;
  willBecomeAd!: boolean;
  mediaType!: CampaignPostContentType;
  targetPlatforms!: PostTargetPlatforms[];
  platform!: PostPlatform;
  calendarPostId!: number;
  suggestedText?: string;
  images!: ImageEntity[];
  carouselSlides!: ImageEntity[];

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "suggestedPublishDate":
        if (!propertyValue) {
          return new Date();
        }
        return dayjs(propertyValue).toDate();
      case "carouselSlides":
      case "images":
        return propertyValue.map(AnyToImageEntityMapper);
      default:
        return propertyValue;
    }
  }
}

export class BrandCampaignFacebookCalendarPost extends BrandCampaignCalendarPost {
  carouselSlides!: ImageEntity[];
  image!: string;
  text!: string;
  videoId?: string;
  videoName?: string;
  videoUrl?: string;
  postToFacebook!: boolean;
  postToInstagram!: boolean;
  platform = PostPlatform.Facebook;

  public isFacebookPostInstance(): this is FacebookPost {
    return true;
  }
}

export class BrandCampaignGoogleCalendarPost extends BrandCampaignCalendarPost {
  descriptionFive!: string;
  descriptionFour!: string;
  descriptionOne!: string;
  descriptionThree!: string;
  descriptionTwo!: string;
  googleAdLogoUrl!: string;
  headlineFive!: string;
  headlineFour!: string;
  headlineOne!: string;
  headlineThree!: string;
  headlineTwo!: string;
  longHeadline!: string;
  platform = PostPlatform.Google;
}

export class BrandCampaignLinkedInCalendarPost extends BrandCampaignCalendarPost {
  images!: ImageEntity[];
  image!: string;
  text!: string;
  videoId?: string;
  videoName?: string;
  videoUrl?: string;
  platform = PostPlatform.LinkedIn;
}
