<span class="close-icon" [mat-dialog-close]="">
  <mat-icon>close</mat-icon>
</span>
<h2 mat-dialog-title [innerHTML]="data.title"></h2>
<div mat-dialog-content>
  <p [innerHTML]="data.message"></p>
  <p *ngIf="data.details" [innerHTML]="data.details"></p>
</div>
<div mat-dialog-actions>
  <button class="btn btn--transparent dismiss" [mat-dialog-close]="false">
    {{ data.cancelButton }}
  </button>
  <button class="btn btn--primary button-left" [mat-dialog-close]="true">
    {{ data.confirmButton }}
  </button>
</div>
