import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface AlertDialogData {
  message: string;
  description: string;
  subDescription?: string;
  acceptButtonText?: string;
}

export type AlertDialogResult = true;

@Component({
  selector: "app-alert-dialog",
  templateUrl: "./alert-dialog.component.html",
  styleUrl: "./alert-dialog.component.scss",
})
export class AlertDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: AlertDialogData,
  ) {}
}
