import dayjs from "dayjs";

import { CampaignPost } from "./campaignPost";
import {
  CampaignPostContentType,
  PostPlatform,
  PostTargetPlatforms,
} from "../enums/campaignPost.enums";

export class PartnerCampaignCalendarPost extends CampaignPost {
  id!: number;
  suggestedPublishDate!: Date;
  suggestedPublishDateTimezone!: string;
  isDarkPost!: boolean;
  willBecomeAd!: boolean;
  mediaType!: CampaignPostContentType;
  targetPlatforms!: PostTargetPlatforms[];
  platform!: PostPlatform;
  calendarPostId!: number;
  suggestedText?: string;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "suggestedPublishDate":
        if (!propertyValue) {
          return new Date();
        }
        return dayjs(propertyValue).toDate();
      default:
        return propertyValue;
    }
  }
}

export class PartnerCampaignGoogleCalendarPost extends PartnerCampaignCalendarPost {
  descriptionFive!: string;
  descriptionFour!: string;
  descriptionOne!: string;
  descriptionThree!: string;
  descriptionTwo!: string;
  googleAdLogoUrl!: string;
  headlineFive!: string;
  headlineFour!: string;
  headlineOne!: string;
  headlineThree!: string;
  headlineTwo!: string;
  longHeadline!: string;
}
