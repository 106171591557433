import sortBy from "lodash/sortBy";
import { CampaignAsset } from "./campaignAsset";
import { PostPlatform } from "../enums/campaignPost.enums";
import { CampaignProduct } from "./campaignProduct";
import { Image } from "./image";
import { PartnerCampaignCalendar } from "./partnerCampaignCalendar";
import { PartnerCampaignCategory } from "./partnerCampaignCategory";
import { Language } from "./language";
import { PartnerFacebookPostLog } from "./partnerFacebookPostLog";
import { PartnerGoogleAdPostLog } from "./partnerGoogleAdPostLog";
import { PartnerLinkedInPostLog } from "./partnerLinkedInPostLog";
import { PartnerPostLog } from "../types/postLog.types";
import { SerializableObject } from "./serializable-object";

export class PartnerCampaignDetails extends SerializableObject {
  id!: number;
  assets!: CampaignAsset[];
  calendars!: PartnerCampaignCalendar[];
  categories!: PartnerCampaignCategory[];
  description!: string;
  fbOtherInformation!: string;
  language!: Language;
  pos!: CampaignProduct[];
  posAdditionalInformation!: string;
  posIntroText!: string;
  postsPerPartner!: PartnerPostLog[];
  sliders!: Image[];
  subtitle!: string;
  title!: string;

  public get orderedCategories(): PartnerCampaignCategory[] {
    if (!this.categories) {
      return [];
    }
    return sortBy(this.categories, (cat: PartnerCampaignCategory) => cat.order);
  }

  public get orderedActiveCategories(): PartnerCampaignCategory[] {
    return this.orderedCategories.filter((c) => c.active);
  }

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "assets":
        return propertyValue.map(
          (asset: any) =>
            new CampaignAsset(
              asset.id,
              asset.file,
              asset.name,
              asset.type,
              asset.size,
            ),
        );
      case "calendars":
        return propertyValue.map(
          (calendar: any) => new PartnerCampaignCalendar(calendar),
        );
      case "categories":
        return propertyValue.map(
          (category: any) => new PartnerCampaignCategory(category),
        );
      case "language":
        return new Language(propertyValue.locale, propertyValue.name);
      case "pos":
        return propertyValue.map((pos: any) => new CampaignProduct(pos));
      case "postsPerPartner":
        return propertyValue.map((post: any) => {
          switch (post.platform) {
            case PostPlatform.Facebook:
              return new PartnerFacebookPostLog();
            case PostPlatform.LinkedIn:
              return new PartnerLinkedInPostLog();
            case PostPlatform.Google:
              return new PartnerGoogleAdPostLog();
          }
        });
      case "sliders":
        return propertyValue.map((slide: any) => new Image(slide));
      default:
        return propertyValue;
    }
  }
}
