import { PostPlatform, PostTargetPlatforms } from "../enums/campaignPost.enums";
import { FacebookPost } from "../types/campaignPost.types";
import { PartnerCampaignPost } from "./partnerCampaignPost";
import { ImageEntity } from "../entities/file/image.entity";
import { AnyToImageEntityMapper } from "../mappers/image.entity.mapper";

export class PartnerCampaignFacebookPost extends PartnerCampaignPost {
  carouselSlides!: ImageEntity[];
  isDarkPost!: boolean;
  link!: string;
  targetPlatforms!: PostTargetPlatforms[];
  text!: string;
  videoId?: string;
  videoFileId!: number | null;
  videoName?: string;
  videoUrl?: string;
  postToFacebook!: boolean;
  postToInstagram!: boolean;
  platform = PostPlatform.Facebook;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "carouselSlides":
        return propertyValue.map(AnyToImageEntityMapper);
      default:
        return super.coercePropertyType(propertyName, propertyValue);
    }
  }
  public isFacebookPostInstance(): this is FacebookPost {
    return true;
  }
}
