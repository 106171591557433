import { SerializableObject } from "./serializable-object";

export enum AddressType {
  Billing = "BILLING",
  Shipping = "SHIPPING",
}

export class Address extends SerializableObject {
  id!: number;
  streetAndNumber!: string;
  postalCode!: string;
  city!: string;
  country!: string;
  additionalInformation!: string;
  type!: AddressType;
  isDefault!: boolean;
  longitude!: number;
  latitude!: number;

  public get isShippingAddress(): boolean {
    return this.type === AddressType.Shipping;
  }

  public get isBillingAddress(): boolean {
    return this.type === AddressType.Billing;
  }

  public get isComplete(): boolean {
    return (
      !!this.streetAndNumber &&
      !!this.postalCode &&
      !!this.city &&
      !!this.country
    );
  }

  static createShipping(id?: number): Address {
    const shippingAddress = new Address();
    // TODO: Default to `null` to keep old behaviour, we need to decide how to express "not-created-model"
    shippingAddress.id = id ?? (null as any);
    shippingAddress.additionalInformation = "";
    shippingAddress.isDefault = false;
    shippingAddress.type = AddressType.Shipping;
    return shippingAddress;
  }

  static createBilling(id?: number): Address {
    const billingAddress = new Address();
    // TODO: Default to `null` to keep old behaviour, we need to decide how to express "not-created-model"
    billingAddress.id = id ?? (null as any);
    billingAddress.additionalInformation = "";
    billingAddress.isDefault = false;
    billingAddress.type = AddressType.Billing;
    return billingAddress;
  }
}

export class CampaignPartnerAddress extends Address {
  isDefault!: boolean;
}
