import { ImageEntity } from "../entities/file/image.entity";
import {
  LinkedInPostContentType,
  PostPlatform,
} from "../enums/campaignPost.enums";
import { AnyToImageEntityMapper } from "../mappers/image.entity.mapper";
import { has48HoursPassed } from "../services/date.service";
import { Image } from "./image";
import { PostLog } from "./postLog";
import dayjs from "dayjs";

export class PartnerLinkedInPostLog extends PostLog {
  images!: ImageEntity[];
  mediaType!: LinkedInPostContentType;
  text!: string;
  videoId?: string;
  videoName?: string;
  videoUrl?: string;
  clicks!: number;
  comments!: number;
  impressions!: number;
  likes!: number;
  link!: string;
  shares!: number;
  platform = PostPlatform.LinkedIn;
  willBecomeAd!: boolean;
  isMissingLinkedInPermissions!: boolean;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "date":
        if (!propertyValue) {
          return new Date();
        }
        return dayjs(propertyValue).toDate();
      case "scheduledPublishDate":
        if (!propertyValue) {
          return new Date();
        }
        const date = dayjs(propertyValue).toDate();
        this.hasValidMetrics = has48HoursPassed(date);
        return date;
      case "imageFile":
        return new Image(propertyValue);
      case "images":
        return propertyValue.map(AnyToImageEntityMapper);
      default:
        return super.coercePropertyType(propertyName, propertyValue);
    }
  }
}
