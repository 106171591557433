import { Mapper } from "../../../../../harmony/core";
import { AdMetrics } from "../../domain/models/ad-metrics";
import { AdMetricsEntity } from "../entities/ad-metrics.entity";

export class AdMetricsEntityToAdMetricsMapper
  implements Mapper<AdMetricsEntity, AdMetrics>
{
  public map(from: AdMetricsEntity): AdMetrics {
    return new AdMetrics(
      from.conversions,
      from.impressions,
      from.inlinePostEngagement,
      from.reach,
      from.spend,
      from.uniqueClicks,
    );
  }
}
