import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface ErrorDialogData {
  message: string;
  title: string;
  details?: Record<string, string>; // todo: throw error in the backend to check the type
  moreInfo?: string;
}
export type ErrorDialogResult = undefined;

@Component({
  selector: "app-error-dialog",
  templateUrl: "./error-dialog.component.html",
  styleUrls: ["./error-dialog.component.scss"],
})
export class ErrorDialogComponent {
  public toggleMoreInfo = false;

  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: ErrorDialogData) {}
}
