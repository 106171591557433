<section class="carousel-wrapper" #carouselWrapper>
  <div class="carousel-inner" #carousel>
    <div
      *ngFor="let item of items; let i = index"
      class="carousel-item"
      [ngClass]="{ 'carousel-item--thumbnail': isThumbnail }"
    >
      <img
        *ngIf="!item.videoUrl"
        (click)="goToSlide(i)"
        class="carousel-item-image"
        [ngClass]="{ 'carousel-item-image--thumbnail': isThumbnail }"
        src="{{ item.image }}"
        loading="lazy"
      />
      <div
        *ngIf="item.videoUrl"
        class="carousel-item-video"
        [ngClass]="{ 'carousel-item-video--thumbnail': isThumbnail }"
      >
        <video
          style="width: 100%"
          src="{{ item.videoUrl }}"
          controls
          preload="metadata"
        ></video>
      </div>
      <a
        *ngIf="!isThumbnail"
        href="{{ item.link }}"
        target="blank"
        class="carousel-item-text"
        >{{ item.text }}</a
      >
    </div>
  </div>
  <div
    *ngIf="showControls && items"
    [ngClass]="{ 'buttons--thumbnail': isThumbnail }"
    class="buttons"
  >
    <button
      (click)="prev()"
      class="button button-prev"
      *ngIf="currentSlide !== 0"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      (click)="next()"
      class="button button-next"
      *ngIf="!(currentSlide + 1 === items.length)"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</section>
