import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

/**
 * Base Domain Object
 */
export abstract class SerializableObject {
  /**
   * Domain object constructor.
   *
   * @param json Optional json used to populate the domain object fields.
   */
  constructor(json?: Record<string, any>) {
    // TODO: Refactor constructors with type arguments and remove this extra check.
    if (!this.isNil(json)) {
      Object.keys(json).forEach((prop) => {
        (this as any)[prop] = this.coercePropertyType(prop, json[prop]);
      });
    }
  }

  /**
   * Clones the current SerializableObject.
   */
  public clone(): this {
    return cloneDeep(this);
  }

  /**
   * Performs a deep comparison between objects to determine if they are equivalent.
   */
  public isClone(otherObj: object): boolean {
    if (this === otherObj) {
      // Don't count the references to the same object as equivalent objects.
      return false;
    }

    return isEqual(this, otherObj);
  }

  /**
   * Coerce property value type. Override this method to convert properties to proper types.
   *
   * @param prop The property name
   * @param value The property value
   */
  protected coercePropertyType(prop: string, value: unknown): unknown | null {
    if (this.isNil(value)) {
      return null;
    }

    return value;
  }

  private isNil(value: unknown): value is undefined | null {
    return value === undefined || value === null;
  }
}
