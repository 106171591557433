import dayjs from "dayjs";
import { AdMetricsEntityToAdMetricsMapper } from "../../features/ad/data/mappers/ad-metrics.mapper";
import { AdEntityToAdMapper } from "../../features/ad/data/mappers/ad.mapper";
import { PostPlatform } from "../enums/campaignPost.enums";
import { has48HoursPassed } from "../services/date.service";
import { Image } from "./image";
import { PostLog } from "./postLog";
import { ImageEntity } from "../entities/file/image.entity";
import { AnyToImageEntityMapper } from "../mappers/image.entity.mapper";

export class PartnerGoogleAdPostLog extends PostLog {
  descriptionFive!: string;
  descriptionFour!: string;
  descriptionOne!: string;
  descriptionThree!: string;
  descriptionTwo!: string;
  googleAdLogoUrl!: string;
  headlineFive!: string;
  headlineFour!: string;
  headlineOne!: string;
  headlineThree!: string;
  headlineTwo!: string;
  longHeadline!: string;
  partnerCompanyName!: string;
  partnerCompanyAdditionalInformation!: string;
  images!: ImageEntity[];
  platform = PostPlatform.Google;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "date":
        if (!propertyValue) {
          return new Date();
        }
        return dayjs(propertyValue).toDate();
      case "scheduledPublishDate":
        if (!propertyValue) {
          return new Date();
        }
        const date = dayjs(propertyValue).toDate();
        this.hasValidMetrics = has48HoursPassed(date);
        return date;
      case "ad":
        if (!propertyValue) {
          return undefined;
        }
        const adMetricsMapper = new AdMetricsEntityToAdMetricsMapper();
        const adMapper = new AdEntityToAdMapper(adMetricsMapper);
        return adMapper.map(propertyValue);
      case "imageFile":
        return new Image(propertyValue);
      case "images":
        return propertyValue.map(AnyToImageEntityMapper);
      default:
        return super.coercePropertyType(propertyName, propertyValue);
    }
  }
}
