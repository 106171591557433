/* eslint-disable @typescript-eslint/naming-convention */
export enum UserPermissionsKey {
  Admin__ImpersonatePartner = "ADMIN__IMPERSONATE_PARTNER",
  Admin__ImpersonateBrand = "ADMIN__IMPERSONATE_BRAND",
  Admin__Partners = "ADMIN__PARTNERS",
  Admin__Partners__Partner = "ADMIN__PARTNERS__PARTNER",
  Admin__Partners__Partner__Details = "ADMIN__PARTNERS__PARTNER__DETAILS",
  Admin__Partners__Partner__TrackingEvents = "ADMIN__PARTNERS__PARTNER__TRACKING_EVENTS",
  Admin__Brands = "ADMIN__BRANDS",
  Admin__Brands__Brand = "ADMIN__BRANDS__BRAND",
  Admin__Brands__Brand__Details = "ADMIN__BRANDS__BRAND__DETAILS",
  Admin__Brands__Brand__FbAdAccounts = "ADMIN__BRANDS__BRAND__FB_AD_ACCOUNTS",
  Admin__Brands__Brand__FbAdAccounts__Create = "ADMIN__BRANDS__BRAND__FB_AD_ACCOUNTS__CREATE",
  Admin__Brands__Brand__FbAdAccounts__Update = "ADMIN__BRANDS__BRAND__FB_AD_ACCOUNTS__UPDATE",
  Admin__Brands__Brand__FbAdAccounts__Delete = "ADMIN__BRANDS__BRAND__FB_AD_ACCOUNTS__DELETE",
  Admin__Ads = "ADMIN__ADS",
  Admin__Ads__Export = "ADMIN__ADS__EXPORT",
  Admin__Ads__Submit = "ADMIN__ADS__SUBMIT",
  Admin__Ads__Retry = "ADMIN__ADS__RETRY",
  Admin__Ads__Reset = "ADMIN__ADS__RESET",
  Admin__Ads__MarkAsInvoiced = "ADMIN__ADS__MARK_AS_INVOICED",
  Admin__Ads__Pause = "ADMIN__ADS__PAUSE",
  Admin__Ads__Resume = "ADMIN__ADS__RESUME",
  Admin__Ads__Ad = "ADMIN__ADS__AD",
  Admin__Ads__Ad__Details = "ADMIN__ADS__AD__DETAILS",
  Admin__Ads__Ad__TrackingEvents = "ADMIN__ADS__AD__TRACKING_EVENTS",
  // TODO: Verify that this is the correct key
  Admin__Ads__Incidents = "ADMIN__ADS__INCIDENTS",
  Admin__Campaigns = "ADMIN__CAMPAIGNS",
  Admin__Campaigns__Download = "ADMIN__CAMPAIGNS__DOWNLOAD",
  Admin__Campaigns__Campaign = "ADMIN__CAMPAIGNS__CAMPAIGN",
  Admin__Campaigns__Campaign__Details = "ADMIN__CAMPAIGNS__CAMPAIGN__DETAILS",
  Admin__Campaigns__Campaign__Details__MoveAdAccount = "ADMIN__CAMPAIGNS__CAMPAIGN__DETAILS__MOVE_AD_ACCOUNT",
  Admin__Campaigns__Campaign__Partners = "ADMIN__CAMPAIGNS__CAMPAIGN__PARTNERS",
  Admin__Campaigns__Campaign__Partners__ApproveCalendar = "ADMIN__CAMPAIGNS__CAMPAIGN__PARTNERS__APPROVE_CALENDAR",
  Admin__Campaigns__Campaign__Partners__PauseCalendar = "ADMIN__CAMPAIGNS__CAMPAIGN__PARTNERS__PAUSE_CALENDAR",
  Admin__Campaigns__Campaign__Partners__ResumeCalendar = "ADMIN__CAMPAIGNS__CAMPAIGN__PARTNERS__RESUME_CALENDAR",
  Admin__Campaigns__Campaign__Partners__AddContact = "ADMIN__CAMPAIGNS__CAMPAIGN__PARTNERS__ADD_CONTACT",
  Admin__Campaigns__Campaign__Partners__ContactTracking = "ADMIN__CAMPAIGNS__CAMPAIGN__PARTNERS__CONTACT_TRACKING",
  Admin__Campaigns__Campaign__Partners__ContactTracking__Create = "ADMIN__CAMPAIGNS__CAMPAIGN__PARTNERS__CONTACT_TRACKING__CREATE",
  Admin__Campaigns__Campaign__Partners__ContactTracking__Update = "ADMIN__CAMPAIGNS__CAMPAIGN__PARTNERS__CONTACT_TRACKING__UPDATE",
  Admin__Campaigns__Campaign__Partners__ContactTracking__Delete = "ADMIN__CAMPAIGNS__CAMPAIGN__PARTNERS__CONTACT_TRACKING__DELETE",
  Admin__Campaigns__Campaign__Ads = "ADMIN__CAMPAIGNS__CAMPAIGN__ADS",
  Admin__Campaigns__Campaign__Ads__Export = "ADMIN__CAMPAIGNS__CAMPAIGN__ADS__EXPORT",
  Admin__Campaigns__Campaign__Ads__Submit = "ADMIN__CAMPAIGNS__CAMPAIGN__ADS__SUBMIT",
  Admin__Campaigns__Campaign__Ads__Reset = "ADMIN__CAMPAIGNS__CAMPAIGN__ADS__RESET",
  Admin__Campaigns__Campaign__Ads__MarkAsInvoiced = "ADMIN__CAMPAIGNS__CAMPAIGN__ADS__MARK_AS_INVOICED",
  Admin__Campaigns__Campaign__Ads__Pause = "ADMIN__CAMPAIGNS__CAMPAIGN__ADS__PAUSE",
  Admin__Campaigns__Campaign__Ads__Resume = "ADMIN__CAMPAIGNS__CAMPAIGN__ADS__RESUME",
  Admin__Campaigns__Campaign__Invoices = "ADMIN__CAMPAIGNS__CAMPAIGN__INVOICES",
  Admin__Campaigns__Campaign__Invoices__Add = "ADMIN__CAMPAIGNS__CAMPAIGN__INVOICES__ADD",
  Admin__Campaigns__Campaign__Invoices__Delete = "ADMIN__CAMPAIGNS__CAMPAIGN__INVOICES__DELETE",
  Admin__Campaigns__Campaign__Invoices__MarkAsPaid = "ADMIN__CAMPAIGNS__CAMPAIGN__INVOICES__MARK_AS_PAID",
  Admin__Config = "ADMIN__CONFIG",
  Admin__Config__GoogleAdsAudiencesUploadFile = "ADMIN__CONFIG__GOOGLE_ADS_AUDIENCES_UPLOAD_FILE",
  Admin__Config__GoogleAdsAudiencesReset = "ADMIN__CONFIG__GOOGLE_ADS_AUDIENCES_RESET",
  Admin__Content = "ADMIN__CONTENT",
}

export type UserPermissionsEntity = UserPermissionsKey[];
