import { SafeUrl } from "@angular/platform-browser";

import { CampaignPost } from "./campaignPost";
import { PostPlatform } from "../enums/campaignPost.enums";
import { Image } from "./image";
import { PartnerCampaignFacebookPost } from "./partnerCampaignFacebookPost";
import { PartnerCampaignGoogleAdPost } from "./partnerCampaignGoogleAdPost";
import { PartnerCampaignLinkedInPost } from "./partnerCampaignLinkedInPost";

export type PartnerPost =
  | PartnerCampaignFacebookPost
  | PartnerCampaignGoogleAdPost
  | PartnerCampaignLinkedInPost;

export class PartnerCampaignPost extends CampaignPost {
  image?: string | SafeUrl;
  imagePublicId!: string;
  imageFileId!: number | null;
  imageFile?: Image;
  suggestedText?: string;
  updatedAt?: Date;
  willBecomeAd?: boolean;
  platform!: PostPlatform;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "imageFile":
        return new Image(propertyValue);
      default:
        return propertyValue;
    }
  }
}
