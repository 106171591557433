<img
  *ngIf="numImages === 1 || post.image"
  [src]="post.image || imagesToShow[0].url"
  class="single-image"
  loading="lazy"
/>
<div class="gallery" *ngIf="imagesToShow.length > 1">
  <div
    class="img-container"
    *ngFor="let image of imagesToShow; let i = index"
    [ngClass]="{
      'img-container--wide': numImages > 2 && !isSecondRow(i),
      'img-container--narrow': numImages > 2 && isSecondRow(i),
      'img-container--two-images': isTwoImagesRow(i),
      'img-container--three-images': numImages > 3 && isSecondRow(i)
    }"
  >
    <div
      class="img"
      [ngStyle]="{ 'background-image': 'url(' + image.url + ')' }"
    ></div>
    <div class="more-layer" *ngIf="totalImages > 5 && i === 4">
      <span class="more-layer__number"> +{{ totalImages - 5 }} </span>
    </div>
  </div>
</div>
