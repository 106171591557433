<div
  class="google-ad"
  [ngClass]="{
    'google-ad--no-min-height': noMinHeight === true,
    'google-ad--min-height': noMinHeight === false,
    'google-ad--wrapper': formatWrapped
  }"
>
  <div class="google-ad-image">
    <img [src]="post.image" loading="lazy" />
  </div>
  <div class="google-ad-title">{{ post.headlineOne }}</div>
  <div class="google-ad-content">
    <div
      class="google-ad-content-column google-ad-content-column--short google-ad-content-column--image"
    >
      <div *ngIf="showNoLogo" class="google-ad-content-no-logo">
        <img src="{{ googleAdLogoUrl }}" loading="lazy" />
      </div>
      <img
        *ngIf="!showNoLogo"
        class="google-ad-content-logo"
        [src]="googleAdLogoUrl"
        alt=""
        loading="lazy"
      />
    </div>
    <div
      class="google-ad-content-column google-ad-content-column--long google-ad-content-text"
    >
      <div class="google-ad-description">{{ post.descriptionOne }}</div>
      <div *ngIf="getAdditionalInfo()" class="google-ad-company-name">
        {{ getAdditionalInfo() }}
      </div>
    </div>
    <div class="google-ad-content-column google-ad-content-column--short">
      <div class="google-ad-content-icon">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </div>
    </div>
  </div>
</div>
