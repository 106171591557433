export class TextCaseTransform {
  public static kebabToCamel(input: string): string {
    return input
      .split("-")
      .reduce(
        (res, word, i) =>
          i === 0
            ? word.toLowerCase()
            : `${res}${word.charAt(0).toUpperCase()}${word
                .substr(1)
                .toLowerCase()}`,
        "",
      );
  }

  public static regularToKebab(input: string): string {
    const out = input.trim().toLowerCase();
    return out.replace(" ", "-");
  }

  public static regularToCapitalized(input: string): string {
    return input
      .split(" ")
      .map((word) => {
        const lowercaseWord = word.toLowerCase();
        return `${lowercaseWord.charAt(0).toUpperCase()}${lowercaseWord.slice(
          1,
        )}`;
      })
      .join(" ");
  }

  public static snakeToCamel(input: string): string {
    return input
      .split("_")
      .reduce(
        (res, word, i) =>
          i === 0
            ? word.toLowerCase()
            : `${res}${word.charAt(0).toUpperCase()}${word
                .substr(1)
                .toLowerCase()}`,
        "",
      );
  }

  public static snakeToKebab(input: string): string {
    return input
      .split("_")
      .reduce(
        (res, word, i) =>
          i === 0 ? word.toLowerCase() : `${res}-${word.toLowerCase()}`,
        "",
      );
  }
}
