import { Injectable, Signal, computed, inject } from "@angular/core";
import dayjs from "dayjs";
import { LanguageService } from "./language.service";
import {
  InvalidArgumentError,
  UnreachableCaseError,
} from "../../../harmony/core";
import clamp from "date-fns/clamp";
import max from "date-fns/max";
import min from "date-fns/min";
import { AppLocale } from "../models/appData";
import { DatePipe } from "@angular/common";

export const FALLBACK_DATE_FORMAT = "dd/MM/YYYY";
export const DE_DATE_FORMAT = "dd.MM.YYYY";

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export enum DateRangePreset {
  Today = "TODAY",
  CurrentWeek = "CURRENT_WEEK",
  LastWeek = "LAST_WEEK",
  CurrentMonth = "CURRENT_MONTH",
  LastMonth = "LAST_MONTH",
  CurrentYear = "CURRENT_YEAR",
  LastYear = "LAST_YEAR",
  AllTime = "ALL_TIME",
}

const getDateFormat = (locale: AppLocale): string =>
  locale === AppLocale.DeDe ? DE_DATE_FORMAT : FALLBACK_DATE_FORMAT;

export const getDateFormatSignal = (): Signal<string> => {
  const languageService = inject(LanguageService);
  return computed(() => getDateFormat(languageService.locale));
};

export const getDateTimeFormatSignal = (): Signal<string> => {
  const languageService = inject(LanguageService);
  return computed(() => `${getDateFormat(languageService.locale)} HH:mm`);
};

@Injectable()
export class DateService {
  constructor(
    private readonly datePipe: DatePipe,
    private readonly language: LanguageService,
  ) {}

  public clamp(value: Date, min: Date, max: Date): Date {
    return clamp(value, { start: min, end: max });
  }

  public formatDate(date: Date): string {
    return this.datePipe.transform(date, this.getDateFormat()) ?? "";
  }

  public getDateFormat(): string {
    return getDateFormat(this.language.locale);
  }

  public getRange(preset: DateRangePreset): DateRange {
    switch (preset) {
      case DateRangePreset.Today:
        return {
          startDate: dayjs().startOf("day").toDate(),
          endDate: dayjs().endOf("day").toDate(),
        };

      case DateRangePreset.CurrentWeek:
        return {
          startDate: dayjs().startOf("week").toDate(),
          endDate: dayjs().endOf("week").toDate(),
        };

      case DateRangePreset.LastWeek:
        return {
          startDate: dayjs().startOf("week").subtract(1, "week").toDate(),
          endDate: dayjs().endOf("week").subtract(1, "week").toDate(),
        };

      case DateRangePreset.CurrentMonth:
        return {
          startDate: dayjs().startOf("month").toDate(),
          endDate: dayjs().endOf("month").toDate(),
        };

      case DateRangePreset.LastMonth:
        return {
          startDate: dayjs().startOf("month").subtract(1, "month").toDate(),
          endDate: dayjs().endOf("month").subtract(1, "month").toDate(),
        };

      case DateRangePreset.CurrentYear:
        return {
          startDate: dayjs().startOf("year").toDate(),
          endDate: dayjs().endOf("year").toDate(),
        };

      case DateRangePreset.LastYear:
        return {
          startDate: dayjs().startOf("year").subtract(1, "year").toDate(),
          endDate: dayjs().endOf("year").subtract(1, "year").toDate(),
        };

      case DateRangePreset.AllTime:
        return {
          startDate: dayjs("2018-01-01").startOf("day").toDate(),
          endDate: dayjs().endOf("day").toDate(),
        };

      default:
        throw new UnreachableCaseError(preset);
    }
  }

  /**
   * Check is date is inside range. This works at `date` level, hours/minutes/… are ignored.
   */
  public isDateInsideRange(date: Date, range: DateRange): boolean {
    const day = dayjs(date);
    const start = dayjs(range.startDate);
    const end = dayjs(range.endDate);

    return (
      (day.isSame(start, "date") || day.isAfter(start, "date")) &&
      (day.isSame(end, "date") || day.isBefore(end, "date"))
    );
  }

  /**
   * Check is date is outside range. This works at `date` level, hours/minutes/… are ignored.
   */
  public isDateOutsideRange(date: Date, range: DateRange): boolean {
    return !this.isDateInsideRange(date, range);
  }

  public max(...dates: Date[]): Date {
    if (dates.length === 0) {
      throw new InvalidArgumentError("You need to provide at least one date");
    }

    return max(dates);
  }

  public min(...dates: Date[]): Date {
    if (dates.length === 0) {
      throw new InvalidArgumentError("You need to provide at least one date");
    }

    return min(dates);
  }

  // TODO: Review timezone handling, remove `toISOString`? This right now forces the dates to be in the user timezone - https://app.asana.com/0/1202486247410328/1204726404757564/f
  public removeRangeTimeZone(range: DateRange): DateRange {
    return {
      startDate: dayjs(range.startDate.toISOString().substring(0, 10))
        .startOf("day")
        .toDate(),
      endDate: dayjs(range.endDate.toISOString().substring(0, 10))
        .endOf("day")
        .toDate(),
    };
  }
}

export function has48HoursPassed(date: Date): boolean {
  const millisecondsIn48Hours = 48 * 60 * 60 * 1000;
  const now = new Date();
  const difference = now.getTime() - date.getTime();

  return difference >= millisecondsIn48Hours;
}
