import dayjs from "dayjs";

import { AdMetricsEntityToAdMetricsMapper } from "../../features/ad/data/mappers/ad-metrics.mapper";
import { has48HoursPassed } from "../services/date.service";
import { FacebookPost } from "../types/campaignPost.types";
import { AdEntityToAdMapper } from "../../features/ad/data/mappers/ad.mapper";
import { PostPlatform, PostTargetPlatforms } from "../enums/campaignPost.enums";
import { Image } from "./image";
import { ImageEntity } from "../entities/file/image.entity";
import { PostLog } from "./postLog";
import { AnyToImageEntityMapper } from "../mappers/image.entity.mapper";

export class BrandFacebookPostLog extends PostLog {
  carouselSlides!: ImageEntity[];
  clicks!: number;
  comments!: number;
  facebookPageId!: string;
  facebookPageName!: string;
  facebookPagePicture!: string;
  facebookPostId!: string;
  facebookPostLogId!: string;
  facebookPostLogPageId!: string;
  facebookPostUrl!: string;
  impressions!: number;
  isDarkPost!: boolean;
  likes!: number;
  link!: string;
  shares!: number;
  targetPlatforms!: PostTargetPlatforms[];
  text!: string;
  videoId?: string;
  videoName?: string;
  videoUrl?: string;
  willBecomeAd?: boolean;
  isMissingFacebookPermissions?: boolean;
  instagramAccountId!: string;
  instagramAccountName!: string;
  instagramAccountPicture!: string;
  postToFacebook!: boolean;
  postToInstagram!: boolean;
  platform = PostPlatform.Facebook;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "date":
        if (!propertyValue) {
          return new Date();
        }
        return dayjs(propertyValue).toDate();
      case "scheduledPublishDate":
        if (!propertyValue) {
          this.hasValidMetrics = false;
          return new Date();
        }
        const date = dayjs(propertyValue).toDate();
        this.hasValidMetrics = has48HoursPassed(date);
        return date;
      case "ad":
        if (!propertyValue) {
          return undefined;
        }
        const adMetricsMapper = new AdMetricsEntityToAdMetricsMapper();
        const adMapper = new AdEntityToAdMapper(adMetricsMapper);
        return adMapper.map(propertyValue);
      case "imageFile":
        return new Image(propertyValue);
      case "carouselSlides":
        return propertyValue.map(AnyToImageEntityMapper);
      default:
        return super.coercePropertyType(propertyName, propertyValue);
    }
  }

  public isFacebookPostInstance(): this is FacebookPost {
    return true;
  }
}
