// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { Environment } from "./environment.type";

export const environment: Environment = {
  production: true,
  staging: false,
  develop: false,
  envName: "production",
  api: {
    baseHymerUrl: "https://socialpals.erwinhymergroup.com/",
    baseUrl: "https://admin.app.socialpals.de/",
    apiPathV1: "api/v1/",
    apiPathV2: "sph/",
    baseGatewayUrl: "https://gateway.socialpals.de/api/v1",
  },
  features: {
    intercom: {
      userIds: [
        2267, // development+partner@socialpals.de
      ],
    },
    prospectPartners: true,
    brandProvidedPartnerUrl: {
      brandIds: [
        1, // TEST Brand
        200, // Test Brand 2
        201, // Test Brand 3
        49, // Alpina
        76, // Einrichtungspartnerring VME GmbH & Co. KG
        86, // Bosch Power Tools AC
        118, // USM U. Schärer Söhne AG
        156, // TVG Touristik Vertriebsgesellschaft mbH
        161, // Raiffeisen-Tours RT-Reisen GmbH
        168, // EK/servicegroup eG
        238, // Demo - DURABLE · Hunke & Jochheim GmbH & Co. KG
        240, // gesund.de GmbH & Co. KG
      ],
    },
    betaCampaignType: {
      brandIds: [
        1, // Test Brand
        200, // Test Brand 2
        201, // Test Brand 3
      ],
      publicationTypes: ["INSTAGRAM_STORY"],
    },
  },
  facebookInfo: {
    appId: "156624061593865",
    cookie: true,
    xfbml: true,
    version: "v20.0",
  },
  google: {
    mapsApiKey: "AIzaSyAy-KnUGUVOU09AAKTYXrx3FCnaJJlN-co",
    tagManagerId: "GTM-K25VBT5Q",
  },
  intercom: {
    appId: "f1sco83t",
    appBase: "https://api-iam.intercom.io",
  },
  sentryDSN: "https://a4ee00ef91dd414aac88b70df258142b@sentry.io/4610425",
  stripeApiKey:
    "pk_live_51H4UFeED3KbwFAdhReeTA83tG6PWFDVisOrTCUZUmPfjb2LchHiPpQiPCGcgsEX2ZtPWkDlqMiQTzGakwW04SUHn00RK23h0jd",
  auth: {
    clientId: "app_client_1",
  },
};
