import { EventEmitter, Injectable } from "@angular/core";
import dayjs from "dayjs";

import { BehaviorSubject, lastValueFrom, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { PartnerNotSelectedError } from "../../errors/partner-not-selected-error";
import { Address } from "../../models/address";
import { CampaignCurrency } from "../../enums/campaign.enums";
import { Image } from "../../models/image";
import { BasicPartnerInfo } from "../../models/BasicPartnerInfo";
import { Partner } from "../../models/partner";
import { ImageRatio } from "../../enums/partner.enums";
import { PartnerCampaign } from "../../models/partnerCampaign";
import { PartnerDashboard } from "../../models/partnerDashboard";
import { PartnerKpiChart } from "../../models/partner-kpi-chart";
import { HttpClient } from "../http-client";
import { StorageKeys } from "../local-storage.service";
import { SessionStorageService } from "../session-storage.service";
import { safeLocalStorage } from "../../utils/safe-storage";

@Injectable()
export class PartnerService {
  public readonly path = "partner";
  public readonly currentPartner$ = new BehaviorSubject<Partner | undefined>(
    undefined,
  );
  public readonly userPartners$ = new BehaviorSubject<
    BasicPartnerInfo[] | undefined
  >(undefined);

  public forcePartnerListReload = new EventEmitter<Partner>();
  public profileProgressUpdated = new EventEmitter<void>();
  public facebookUserAccessToken?: string;

  constructor(
    private readonly http: HttpClient,
    private readonly sessionStorageService: SessionStorageService,
  ) {}

  public async setCurrentPartnerId(
    partnerId: number,
  ): Promise<Partner | undefined> {
    const currentPartner = await lastValueFrom(
      this.fetchCurrentPartner(partnerId),
    );
    this.setCurrentPartner(currentPartner);
    return currentPartner;
  }

  // This function can only be used in components after the PartnerResolver
  public get currentPartner(): Partner {
    const currentPartner = this.currentPartner$.value;

    if (!currentPartner) {
      throw new PartnerNotSelectedError();
    }

    return currentPartner;
  }

  // This function can only be used in components after the PartnerResolver
  public get currentPartnerId(): number {
    return this.currentPartner.id;
  }

  public get lastSelectedPartnerId(): number | undefined {
    const id = safeLocalStorage.getItem(StorageKeys.CurrentPartnerId);
    return id !== null ? Number(id) : undefined;
  }

  get currentPartnerCompanyName(): string | undefined {
    try {
      return this.currentPartner.companyName;
    } catch (error) {
      return undefined;
    }
  }

  public setCurrentPartner(partner: Partner | undefined): void {
    this.currentPartner$.next(partner);

    if (partner) {
      safeLocalStorage.setItem(
        StorageKeys.CurrentPartnerId,
        partner.id.toString(),
      );
    } else {
      safeLocalStorage.removeItem(StorageKeys.CurrentPartnerId);
    }
  }

  public clearCurrentPartner(): void {
    this.currentPartner$.next(undefined);
    this.sessionStorageService.remove(StorageKeys.CurrentPartnerId);
  }

  public fetchCurrentPartner(
    partnerId?: number,
  ): Observable<Partner | undefined> {
    let currentPartnerId: number | undefined;
    try {
      currentPartnerId = partnerId ?? this.currentPartnerId;
    } catch {
      currentPartnerId = undefined;
    }

    if (!currentPartnerId) {
      return of(undefined);
    }

    return this.get(currentPartnerId).pipe(
      map((partner) => {
        this.currentPartner$.next(partner);
        return partner;
      }),
    );
  }

  public async getCurrentPartner(): Promise<Partner | undefined> {
    if (this.currentPartner?.id) {
      return this.currentPartner;
    } else if (this.currentPartnerId) {
      const partner = await lastValueFrom(this.get(this.currentPartnerId));
      this.currentPartner$.next(partner);
      return partner;
    } else {
      this.currentPartner$.next(undefined);
      return undefined;
    }
  }

  public async getPartnerIdFromUser(): Promise<number | undefined> {
    return lastValueFrom(this.getPartners()).then(
      (partners) => {
        if (partners.length > 0) {
          const lastSelectedPartnerId =
            this.sessionStorageService.getWithFallback(
              StorageKeys.CurrentPartnerId,
            );

          // check if there was a previously selected partner
          if (lastSelectedPartnerId) {
            const isPartnerInList = partners.some(
              (p) => p.id === +lastSelectedPartnerId,
            );

            if (isPartnerInList) {
              return +lastSelectedPartnerId;
            }
          }

          // otherwise set the first partner as the current partner
          return partners[0].id;
        }

        return undefined;
      },
      () => undefined,
    );
  }

  getPartners(offset = 0, limit = 100): Observable<BasicPartnerInfo[]> {
    return this.http
      .get(this.path + "?offset=" + offset + "&limit=" + limit)
      .pipe(
        map(({ body }) => {
          const partners = body.items.map(
            (partner: BasicPartnerInfo) =>
              new BasicPartnerInfo(partner.id, partner.companyName),
          );
          this.userPartners$.next(partners);
          return partners;
        }),
      );
  }

  public create(partner: Partner): Observable<Partner> {
    return this.http.post(this.path, JSON.stringify(partner)).pipe(
      map(({ body }) => {
        const partner = new Partner(body);
        this.currentPartner$.next(partner);
        this.forcePartnerListReload.emit(partner);
        return partner;
      }),
    );
  }

  get(id: number): Observable<Partner> {
    if (!id) {
      return of(new Partner());
    }
    return this.http
      .get(`${this.path}/${id}`)
      .pipe(map(({ body }) => new Partner(body)));
  }

  update(partnerData: Partner): Observable<Partner> {
    return this.http
      .put(`${this.path}/${partnerData.id}`, JSON.stringify(partnerData))
      .pipe(
        map(({ body }) => {
          const partner = new Partner(body);
          this.currentPartner$.next(partner);
          return partner;
        }),
      );
  }

  addFile(
    partner: Partner,
    type: "file" | "logo",
    image: Image,
  ): Observable<Image> {
    return this.http
      .post(`${this.path}/${partner.id}/${type}`, JSON.stringify(image))
      .pipe(
        map(({ body }) => {
          this.currentPartner$.next(partner);
          return new Image(body);
        }),
      );
  }

  deleteFile(imageId: number): Observable<void> {
    const partnerId = this.currentPartnerId ?? this.currentPartner?.id;
    return this.http
      .del(`${this.path}/${partnerId}/file/${imageId}`)
      .pipe(map(() => {}));
  }

  claimPage(partnerId: number): Observable<Partner> {
    return this.http
      .post(`${this.path}/${partnerId}/claim`, {})
      .pipe(map(({ body }) => new Partner(body)));
  }

  claimStatus(partnerId: number): Observable<Partner> {
    return this.http
      .get(`${this.path}/${partnerId}/claim_status`)
      .pipe(map(({ body }) => new Partner(body)));
  }

  resetStatus(partner: Partner): Observable<Partner> {
    return this.http.post(`${this.path}/${partner.id}/reset_status`, {}).pipe(
      map(({ body }) => {
        this.currentPartner$.next(partner);
        return new Partner(body);
      }),
    );
  }

  connectPartnerToFacebook(
    facebookPageId: string,
    facebookPageAccessToken: string,
    facebookPageName: string,
    facebookPageLikes: number,
    facebookPagePicture: string,
    extraPartnerIds: number[] = [],
  ): Observable<Partner> {
    const facebookUserAccessToken = this.facebookUserAccessToken;
    return this.http
      .post(`${this.path}/${this.currentPartnerId}/connect_to_facebook`, {
        facebookPageId: facebookPageId,
        facebookPageAccessToken: facebookPageAccessToken,
        facebookPageName: facebookPageName,
        facebookPageLikes: facebookPageLikes,
        facebookPagePicture: facebookPagePicture,
        extraPartnerIds: extraPartnerIds,
        facebookUserAccessToken: facebookUserAccessToken,
      })
      .pipe(map(({ body }) => new Partner(body)));
  }

  shareFacebookConnection(partnerIds: number[]): Observable<Partner> {
    return this.http
      .post(`${this.path}/${this.currentPartnerId}/share_facebook_connection`, {
        partnerIds: partnerIds,
      })
      .pipe(map(({ body }) => new Partner(body)));
  }

  updateCampaignPartner(
    partnerId: number,
    campaignId: number,
    partnerData: any,
  ): Observable<PartnerCampaign> {
    return this.http
      .put(
        `${this.path}/${partnerId}/campaign/${campaignId}/partner`,
        partnerData,
      )
      .pipe(map(({ body }) => new PartnerCampaign(body)));
  }

  addPartnerToCampaignFromLinkInvite(
    partnerId: number,
    campaignSlug: string,
  ): Observable<Response> {
    return this.http
      .post(
        `accept-campaign-from-invite-link`,
        JSON.stringify({
          partnerId: partnerId,
          campaignSlug: campaignSlug,
        }),
      )
      .pipe(map(({ body }) => body));
  }

  addPartnerToCampaign(partnerId: number, data: any): Observable<Response> {
    return this.http
      .post(`partner/${partnerId}/invite_code`, JSON.stringify(data))
      .pipe(map(({ body }) => body));
  }

  updateAddress(partnerId: number, address: Address): Observable<Address> {
    return this.http
      .put(`${this.path}/${partnerId}/address/${address.id}`, address)
      .pipe(map(({ body }) => new Address(body)));
  }

  createAddress(partnerId: number, address: Address): Observable<Address> {
    return this.http
      .post(`${this.path}/${partnerId}/address`, address)
      .pipe(map(({ body }) => new Address(body)));
  }

  deleteAddress(partnerId: number, addressId: number): Observable<Response> {
    return this.http
      .del(`${this.path}/${partnerId}/address/${addressId}`)
      .pipe(map(({ body }) => body));
  }

  getDashboard(
    bestPostsFilterBy: string,
    bestAdsFilterBy: string,
    currency: CampaignCurrency,
  ): Observable<PartnerDashboard> {
    const partnerId = this.currentPartnerId;
    return this.http
      .get(
        `${this.path}/${partnerId}/dashboard?order_posts_by=` +
          bestPostsFilterBy +
          "&order_ads_by=" +
          bestAdsFilterBy +
          "&currency=" +
          currency,
      )
      .pipe(map(({ body }) => new PartnerDashboard(body)));
  }

  getDashboardKPIChart(
    kpiChartStartDate: Date,
    kpiChartEndDate: Date,
    currency: CampaignCurrency,
  ): Observable<PartnerKpiChart> {
    const partnerId = this.currentPartnerId;
    return this.http
      .get(
        `${this.path}/${partnerId}/dashboard_kpi_chart?` +
          `start_date=` +
          dayjs(kpiChartStartDate).format("YYYY-MM-DD") +
          `&end_date=` +
          dayjs(kpiChartEndDate).format("YYYY-MM-DD") +
          "&currency=" +
          currency,
      )
      .pipe(
        map(
          ({ body }) =>
            new PartnerKpiChart(
              body.series,
              body.publishedPosts,
              body.publishedAds,
              body.likes,
              body.spentBudget,
              body.postViews,
              body.adViews,
              body.reach,
              body.linkClicks,
            ),
        ),
      );
  }

  addLogo(logo: string, ratio: ImageRatio): Observable<Image> {
    const partnerId = this.currentPartnerId ?? this.currentPartner?.id;
    return this.http
      .post(`${this.path}/${partnerId}/logo`, {
        file: logo,
        ratio: ratio,
      })
      .pipe(map(({ body }) => new Image(body)));
  }

  public deleteLogo(logo: Image): Observable<Response> {
    const partnerId = this.currentPartnerId ?? this.currentPartner?.id;
    return this.http
      .del(`${this.path}/${partnerId}/logo/${logo.id}`)
      .pipe(map(({ body }) => body));
  }

  public getPartnerUploadFileUrl(): string {
    const partnerId = this.currentPartnerId;
    return this.http.createRequestUrlV1(`partner/${partnerId}/file`);
  }
}
