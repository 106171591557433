import { Component, Input, OnInit } from "@angular/core";
import { CampaignPost } from "../../../models/campaignPost";
import { PartnerFacebookPostLog } from "../../../models/partnerFacebookPostLog";
import { Partner } from "../../../models/partner";
import { BrandFacebookPostLog } from "../../../models/brandFacebookPostLog";
import {
  FacebookPostPreviewType,
  MockBorderType,
} from "../facebook-post-mock-up/facebook-post-mock-up.component";

@Component({
  selector: "app-generic-post-mock-up",
  templateUrl: "./generic-post-mock-up.component.html",
  styleUrls: ["./generic-post-mock-up.component.scss"],
})
export class GenericPostMockUpComponent implements OnInit {
  @Input({ required: true }) post!: CampaignPost;
  @Input() partner?: Partner;

  public facebookPreviewType = FacebookPostPreviewType.Facebook;
  public readonly FacebookPreviewType = FacebookPostPreviewType;
  public BORDER_TYPE_RIGHT_EMPTY = MockBorderType.RightEmpty;

  public ngOnInit(): void {
    this.setCurrentMediaPreview();
  }

  public showMediaToggleButton(): boolean {
    if (this.post.isFacebookPost && this.post.isFacebookPostInstance()) {
      if (
        this.post instanceof PartnerFacebookPostLog ||
        this.post instanceof BrandFacebookPostLog
      ) {
        if (!this.post.instagramAccountId) {
          return false;
        }
      }
      return this.post.postToFacebook && this.post.postToInstagram;
    }
    return false;
  }

  public showFacebookPreview(): boolean {
    if (this.post.isFacebookPostInstance()) {
      if (!this.post.postToInstagram) {
        return true;
      }
      return this.facebookPreviewType === this.FacebookPreviewType.Facebook;
    }
    return false;
  }

  public showInstagramPreview(): boolean {
    if (!this.post.isFacebookPost || this.showFacebookPreview()) {
      return false;
    }
    return this.facebookPreviewType === this.FacebookPreviewType.Instagram;
  }

  public toggleMediaPreviewType(): void {
    this.facebookPreviewType =
      this.facebookPreviewType === FacebookPostPreviewType.Facebook
        ? FacebookPostPreviewType.Instagram
        : FacebookPostPreviewType.Facebook;
  }

  public setCurrentMediaPreview(): void {
    if (this.post.isFacebookPostInstance()) {
      this.facebookPreviewType = this.post.postToFacebook
        ? FacebookPostPreviewType.Facebook
        : FacebookPostPreviewType.Instagram;
    }
  }
}
