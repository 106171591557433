import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CampaignPost } from "../../models/campaignPost";
import { Partner } from "../../models/partner";

export interface PostPreviewDialogData {
  readonly post: CampaignPost;
  readonly partner?: Partner;
}

export type PostPreviewDialogResult = undefined;

@Component({
  templateUrl: "./post-preview-dialog.component.html",
  styleUrl: "./post-preview-dialog.component.scss",
})
export class PostPreviewDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) protected readonly data: PostPreviewDialogData,
  ) {}
}
