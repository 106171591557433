// eslint-disable-file

export function assert(condition: unknown, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}

export function isNonEmptyString(value: unknown): value is string {
  return typeof value === "string" && value.length > 0;
}

export function isHtmlElement(element: unknown): element is HTMLElement {
  return element instanceof HTMLElement;
}

export function assertHtmlElement(
  element: unknown,
): asserts element is HTMLElement {
  assert(isHtmlElement(element), "Element is not an HTMLElement");
}

export function isHtmlInput(element: unknown): element is HTMLInputElement {
  return element instanceof HTMLInputElement;
}

export function assertHtmlInput(
  element: unknown,
): asserts element is HTMLInputElement {
  assert(isHtmlInput(element), "Element is not an HTMLInputElement");
}
