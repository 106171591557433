import { CampaignBannerMediaType } from "../../enums/campaign.enums";

export class BannerEntity {
  constructor(
    public readonly id: number,
    public readonly files: BannerFileEntity[],
    public readonly mediaType: CampaignBannerMediaType,
    public title: string, // TODO: Change to `readonly`
  ) {}
}

export class BannerFileEntity {
  constructor(
    public readonly id: number,
    public readonly file: string,
    public readonly filePublicId: string,
    public readonly fileType: string,
    public readonly name: string,
  ) {}
}
