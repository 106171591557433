import { VideoEntity } from "../entities/file/video.entity";

export const AnyToVideoEntityMapper = (from: any) =>
  new VideoEntity(
    // TODO(fix-video-entity): Entity A
    from.id,
    from.url,
    from.description,
    from.fileType,
    from.imagePublicId,
    from.title,
    from.videoDuration,
    from.videoPublicId,
    from.videoUrl,
    from.widthXHeight,
    // TODO(fix-video-entity): Entity B
    from.downloadLink,
    from.image,
    from.link,
    from.videoName,
  );
