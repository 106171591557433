import {
  HttpClient as NgHttpClient,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { environment as env } from "../../../environments/environment";
import { AuthTokenService } from "./api/auth-token.service";

export interface IRequestOptions {
  headers: HttpHeaders;
  observe: "response";
  responseType: "json";
}

@Injectable()
export class HttpClient {
  private token?: string;
  private lastUnauthorizedResponseTime?: number;

  constructor(
    private http: NgHttpClient,
    protected readonly authTokenService: AuthTokenService,
  ) {}

  public createRequestUrlV1(path: string): string {
    return env.api.baseUrl + env.api.apiPathV1 + path;
  }

  public createRequestUrlV2(path: string): string {
    return env.api.baseUrl + env.api.apiPathV2 + path;
  }

  public createRequestOptions(): IRequestOptions {
    if (this.authTokenService.userToken) {
      this.token = this.authTokenService.userToken;
    } else {
      this.token = undefined;
    }

    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
      "Content-Type": "application/json",
    });

    return {
      observe: "response",
      responseType: "json",
      headers: headers,
    };
  }

  public isTokenValid(): Observable<HttpResponse<object>> {
    return this.intercept(
      this.http.get(
        this.createRequestUrlV1("ping"),
        this.createRequestOptions(),
      ),
    );
  }

  public get<T = any>(url: string): Observable<HttpResponse<T>> {
    return this.intercept(
      this.http.get<T>(
        this.createRequestUrlV1(url),
        this.createRequestOptions(),
      ),
    );
  }

  public getV2<T = any>(url: string): Observable<HttpResponse<T>> {
    return this.intercept(
      this.http.get<T>(
        this.createRequestUrlV2(url),
        this.createRequestOptions(),
      ),
    );
  }

  public post<T = any>(url: string, data: any): Observable<HttpResponse<T>> {
    return this.intercept(
      this.http.post<T>(
        this.createRequestUrlV1(url),
        data,
        this.createRequestOptions(),
      ),
    );
  }

  public postV2<T = any>(url: string, data: any): Observable<HttpResponse<T>> {
    return this.intercept(
      this.http.post<T>(
        this.createRequestUrlV2(url),
        data,
        this.createRequestOptions(),
      ),
    );
  }

  public put<T = any>(url: string, data: any): Observable<HttpResponse<T>> {
    return this.intercept(
      this.http.put<T>(
        this.createRequestUrlV1(url),
        data,
        this.createRequestOptions(),
      ),
    );
  }

  /* `delete` is a reserved keyword in JS, name method as `del` instead */
  public del<T = any>(url: string): Observable<HttpResponse<T>> {
    return this.intercept(
      this.http.delete<T>(
        this.createRequestUrlV1(url),
        this.createRequestOptions(),
      ),
    );
  }

  private intercept<T = any>(
    response: Observable<HttpResponse<T>>,
  ): Observable<HttpResponse<T>> {
    return response.pipe(
      tap(
        () => {},
        (error) => {
          if (error.status === 401) {
            const now = new Date().getTime();

            if (
              !this.lastUnauthorizedResponseTime ||
              now - this.lastUnauthorizedResponseTime > 5000
            ) {
              this.token = undefined;
              this.lastUnauthorizedResponseTime = now;
              throw error;
            }
          }
        },
      ),
    );
  }
}
