import { ByteUnit } from "../../../functions/byte-unit";
import { UploaderResponseError } from "./uploader-response-error";

export class InvalidSizeUploaderResponseError extends UploaderResponseError {
  constructor(
    maxFileSize: number,
    public message = "fileUploader.invalidSize",
  ) {
    super(message);
    this.interpolateParams = {
      maxFileSize: ByteUnit.makingBytesReadable(maxFileSize),
    };
  }
}
