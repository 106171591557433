import { Pipe, PipeTransform } from "@angular/core";
import isObject from "lodash/isObject";

@Pipe({
  name: "keys",
  pure: false,
})
export class KeysPipe implements PipeTransform {
  public transform(value: unknown): string[] {
    return isObject(value) ? Object.keys(value) : [];
  }
}
