<div class="instagram-post">
  <div class="instagram-post-header">
    <div class="instagram-post-header-info">
      @if (hasInstagramAccount) {
        <img
          src="{{
            instagramAccountPicture ??
              'assets/images/icons/as_instagram-user.svg'
          }}"
          class="instagram-post-header-info-image"
          loading="lazy"
        />
        @if (instagramAccountName) {
          <span class="instagram-post-header-info-name">
            {{ instagramAccountName }}
          </span>
        } @else {
          <div class="instagram-post-header-info-mock-name"></div>
        }
      } @else {
        <div class="instagram-post-header-info-no-image">
          <img src="assets/images/icons/ic_instagram.svg" />
        </div>
        <span class="instagram-post-header-info-name">
          {{ "shared.instagramNotConnected" | translate }}
        </span>
      }
    </div>
    <div class="instagram-post-header-dots">
      <img src="/assets/images/icons/ic_facebookOptions.svg" width="15" />
    </div>
  </div>
  <div class="instagram-post-header-dark-post-badge" *ngIf="post.isDarkPost">
    <img src="/assets/images/icons/ic_dark_post_Calendar.svg" />
    {{ "shared.darkPost" | translate }}
  </div>
  <div class="instagram-post-media">
    <!-- 360 IMAGE -->
    <app-panoramic-viewer
      *ngIf="post.mediaType === ContentType.Image360 && post.image"
      [imageUrl]="post.image"
    />
    <!-- IMAGE & GIF -->
    <img
      *ngIf="post.isRenderedAsImage"
      [src]="post.image"
      alt=""
      loading="lazy"
    />
    <!-- CAROUSEL -->
    <ng-container *ngIf="post.mediaType === ContentType.Carousel">
      <app-carousel>
        <div
          carouselItem
          *ngFor="let item of post.carouselSlides"
          [image]="item.url"
          [text]="item.title"
          [videoUrl]="item.videoUrl"
          [link]="item.link"
        ></div>
      </app-carousel>
    </ng-container>
    <!-- VIDEO -->
    <video
      class="instagram-post-media-video"
      *ngIf="post.mediaType === ContentType.Video"
      src="{{ post.videoUrl }}"
      preload="metadata"
      controls
    ></video>
  </div>
  <div class="instagram-post-icons">
    <div class="instagram-post-icons-group">
      <div>
        <img
          src="/assets/images/icons/ic_instagram_like.svg"
          height="24"
          width="28"
        />
      </div>
      <div>
        <img
          src="/assets/images/icons/ic_instagram_comment.svg"
          height="24"
          width="21"
        />
      </div>
      <div>
        <img
          src="/assets/images/icons/ic_instagram_send.svg"
          height="26"
          width="25"
        />
      </div>
    </div>
    <div class="instagram-post-icons-group">
      <img
        src="/assets/images/icons/ic_instagram_bookmark.svg"
        height="24"
        width="19"
      />
    </div>
  </div>
  <div class="instagram-post-text" [innerHTML]="post.text"></div>
</div>
