import { SafeUrl } from "@angular/platform-browser";
import { Ad } from "../../features/ad/domain/models/ad";
import {
  CampaignPostContentType,
  LinkedInPostContentType,
  PostPartnerStatus,
  PostPlatform,
} from "../enums/campaignPost.enums";
import { CampaignPost, MAX_INSTAGRAM_VIDEO_DURATION } from "./campaignPost";
import { Image } from "./image";
import { PartnerCampaignCalendarPost } from "./partnerCampaignCalendarPost";

export abstract class PostLog extends CampaignPost {
  ad?: Ad;
  date!: Date;
  hasAd!: boolean;
  partnerId!: number;
  partnerStatus!: PostPartnerStatus;
  calendarPostId?: number; // Reference to the original Post ID of which this is a copy for the calendar
  postedToPlatformAt?: boolean;
  postingToPlatformFailed?: boolean;
  publishedByPlatform!: boolean;
  publishedByPlatformAt?: string;
  rescheduled?: boolean;
  scheduledPublishDate!: Date;
  image?: string | SafeUrl;
  imagePublicId!: string;
  imageFile?: Image;
  isPostLog!: boolean;
  mediaType!: CampaignPostContentType | LinkedInPostContentType;
  calendarPost?: PartnerCampaignCalendarPost;
  platform!: PostPlatform;
  suggestedText?: string;
  updatedAt?: Date;
  isPublishedByInstagram!: boolean;
  postUrl!: string;
  hasValidMetrics!: boolean;

  public get canBePostedToInstagram(): boolean {
    if (
      this.mediaType === CampaignPostContentType.Video &&
      this.videoDuration > MAX_INSTAGRAM_VIDEO_DURATION
    ) {
      return false;
    }

    return (
      this.mediaType === CampaignPostContentType.Image ||
      this.mediaType === CampaignPostContentType.Video
    );
  }

  // TODO: We should just remove `hasAd`, and rename this to `hasAd()`. We'll need a method so we can use a type guard, getters don't work.
  public hasAdProp(): this is PostLog & Required<Pick<PostLog, "ad">> {
    return typeof this.ad !== "undefined";
  }

  public get isPartnerApproved(): boolean {
    return this.partnerStatus === PostPartnerStatus.Approved;
  }
}
