<span class="close-icon" mat-dialog-close>
  <mat-icon>close</mat-icon>
</span>
<h2 mat-dialog-title [innerHTML]="data.message | translate"></h2>
<mat-dialog-content *ngIf="data.description || data.subDescription">
  <p
    class="description"
    *ngIf="data.description"
    [innerHTML]="data.description | translate"
  ></p>
  <p
    *ngIf="data.subDescription"
    [innerHTML]="data.subDescription | translate"
  ></p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    class="btn btn--primary button-left"
    [mat-dialog-close]="true"
    [tabIndex]="-1"
  >
    {{ data.acceptButtonText || "shared.accept" | translate }}
  </button>
</mat-dialog-actions>
