import { Injectable, Signal, computed, inject, signal } from "@angular/core";
import { Subject } from "rxjs";

import {
  AppDataCountry,
  AppLocale,
  AppDataPrefix,
  AppLanguage,
  CountryTranslations,
  ExternalUrl,
  isAppLocale,
  AppData,
} from "../models/appData";
import { safeLocalStorage } from "../utils/safe-storage";

export const DEFAULT_LOCALE = AppLocale.DeDe;
export const FALLBACK_LOCALE = AppLocale.EnGb;
const STORAGE_LOCALE_KEY = "localeId";

interface LanguageServiceChangedEvent {
  locale: AppLocale;
  language: AppLanguage;
}

type CountryWithOrder = AppDataCountry &
  Required<Pick<AppDataCountry, "order">>;

export const getLocaleSignal = (): Signal<AppLocale> =>
  inject(LanguageService).getLocaleSignal();
export const getLanguageSignal = (): Signal<AppLanguage> =>
  inject(LanguageService).getLanguageSignal();

export const getStandardLocaleSignal = (): Signal<string> =>
  computed(() => getLocaleSignal()().replace("_", "-"));

export const getTranslatedCountriesSignal = (
  appData: Signal<AppData>,
): Signal<AppDataCountry[]> => {
  const languageService = inject(LanguageService);

  return computed(() =>
    languageService.getTranslatedCountries(
      appData().countries,
      languageService.locale,
    ),
  );
};

export const getTranslatedUrlSignal = (
  externalUrl: ExternalUrl,
): Signal<string> => {
  const languageService = inject(LanguageService);

  return computed(() =>
    languageService.getTranslatedExternalUrls(
      externalUrl,
      languageService.locale,
    ),
  );
};

export function getInitialLocale(): AppLocale {
  let locale: string | undefined;

  try {
    locale = safeLocalStorage.getItem(STORAGE_LOCALE_KEY) ?? undefined;
  } catch {
    locale = navigator.language.replace("-", "_");
  }

  return locale && isAppLocale(locale) ? locale : DEFAULT_LOCALE;
}

@Injectable()
export class LanguageService {
  public readonly changed$ = new Subject<LanguageServiceChangedEvent>();

  private readonly localeSignal = signal(DEFAULT_LOCALE);
  private readonly languageSignal = computed(
    () => this.localeSignal().substring(0, 2) as AppLanguage,
  );

  /** Get Locale. Fires the internal Signal, so it's reactive. */
  get locale(): AppLocale {
    return this.localeSignal();
  }

  public getLocaleSignal(): Signal<AppLocale> {
    return this.localeSignal.asReadonly();
  }

  /** Get Language. Fires the internal Signal, so it's reactive. */
  get language(): AppLanguage {
    return this.languageSignal();
  }

  public getLanguageSignal(): Signal<AppLanguage> {
    return this.languageSignal;
  }

  public setLocale(locale: AppLocale): void {
    this.localeSignal.set(locale);
    safeLocalStorage.setItem(STORAGE_LOCALE_KEY, locale);

    this.changed$.next({
      locale: locale,
      language: this.language,
    });
  }

  private getTranslatedExternalUrlsData(
    locale: AppLocale,
  ): Record<ExternalUrl, string> {
    return require(`../../../assets/locale/${locale}_urls.json`);
  }

  public getTranslatedExternalUrls(
    externalUrl: ExternalUrl,
    locale: AppLocale,
  ): string {
    return this.getTranslatedExternalUrlsData(locale)[externalUrl];
  }

  public getTranslatedPrefixes(
    appDataCountries: AppDataCountry[],
    appDataPrefixes: AppDataPrefix[],
    locale: AppLocale,
  ): Required<AppDataPrefix>[] {
    const codeToTranslatedNameMap = this.getTranslatedCountriesMap(locale);

    return this.sortPrefixes(
      this.getTopPrefixes(appDataCountries),
      appDataPrefixes.map((prefix) => ({
        ...prefix,
        translatedName: codeToTranslatedNameMap.get(prefix.code) ?? prefix.name,
      })),
    );
  }

  private getTopPrefixes(countries: AppDataCountry[]): string[] {
    return countries
      .filter(
        (country): country is CountryWithOrder =>
          typeof country.order !== "undefined",
      )
      .sort((a, b) => a.order - b.order)
      .map(({ code }) => code);
  }

  private sortPrefixes(
    topPrefixes: string[],
    prefixes: Required<AppDataPrefix>[],
  ): Required<AppDataPrefix>[] {
    const map = new Map(prefixes.map((prefix) => [prefix.code, prefix]));
    const top = topPrefixes
      .map((code) => map.get(code))
      .filter((p): p is Required<AppDataPrefix> => typeof p !== "undefined");
    const rest = prefixes.filter(({ code }) => !topPrefixes.includes(code));

    rest.sort((a, b) => a.translatedName.localeCompare(b.translatedName));

    return top.concat(rest);
  }

  public getTranslatedCountries(
    countries: AppDataCountry[],
    locale: AppLocale,
  ): AppDataCountry[] {
    const codeToTranslatedNameMap = this.getTranslatedCountriesMap(locale);

    return countries.map((country) => ({
      ...country,
      translatedName: codeToTranslatedNameMap.get(country.code) ?? country.name,
    }));
  }

  private getTranslatedCountriesData(
    locale: AppLocale = FALLBACK_LOCALE,
  ): CountryTranslations[] {
    return require(`../../../assets/locale/${locale}_countries.json`);
  }

  private getTranslatedCountriesMap(locale: AppLocale): Map<string, string> {
    return new Map(
      this.getTranslatedCountriesData(locale).map((country) => [
        country.alpha2,
        country.name,
      ]),
    );
  }
}
