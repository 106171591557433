export class BrandAddress {
  constructor(
    public readonly id: number,
    public readonly street: string,
    public readonly number: string,
    public readonly postalCode: string,
    public readonly city: string,
    public readonly country: string,
    public readonly longitude: number,
    public readonly latitude: number,
  ) {}
}
