import {
  animate,
  AnimationBuilder,
  AnimationPlayer,
  style,
} from "@angular/animations";
import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";

import {
  CarouselItemDirective,
  CarouselItemElementDirective,
} from "./carousel-item.directive";

@Component({
  selector: "app-carousel",
  exportAs: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements AfterViewInit {
  @ContentChildren(CarouselItemDirective)
  protected items?: QueryList<CarouselItemDirective>;
  @ViewChildren(CarouselItemElementDirective, { read: ElementRef })
  private itemsElements?: QueryList<ElementRef>;

  @ViewChild("carousel") private carousel!: ElementRef;
  @ViewChild("carouselWrapper")
  private carouselWrapper!: ElementRef;

  @Input() public showControls = true;
  @Input() public isThumbnail = false;

  protected currentSlide = 0;

  constructor(
    private builder: AnimationBuilder,
    private renderer: Renderer2,
  ) {}

  public ngAfterViewInit(): void {
    if (this.itemsElements && this.itemsElements.length) {
      this.renderer.addClass(this.itemsElements.first.nativeElement, "current");
    }
  }

  private createAnimationPlayer(el: any, offset: number): AnimationPlayer {
    return this.builder
      .build([
        animate(
          "350ms ease-in",
          style({ transform: `translateX(-${offset}px)` }),
        ),
      ])
      .create(el);
  }

  protected next(): void {
    if (this.currentSlide + 1 === this.items?.length) {
      return;
    }

    this.currentSlide = (this.currentSlide + 1) % (this.items?.length ?? 1);
    this.animate();
  }

  protected prev(): void {
    if (this.currentSlide === 0) {
      return;
    }

    this.currentSlide =
      (this.currentSlide - 1 + (this.items?.length ?? 0)) %
      (this.items?.length ?? 1);
    this.animate();
  }

  protected goToSlide(index: number): void {
    this.currentSlide = index;
    this.animate();
  }

  private animate(): void {
    if (!this.itemsElements) {
      return;
    }

    const itemWidth =
      this.itemsElements.first.nativeElement.getBoundingClientRect().width;
    const wrapperWidth =
      this.carouselWrapper.nativeElement.getBoundingClientRect().width;

    const offset =
      this.currentSlide === 0
        ? 0
        : (itemWidth + 8) * this.currentSlide - (wrapperWidth - itemWidth) / 2;

    const player = this.createAnimationPlayer(
      this.carousel.nativeElement,
      Math.abs(offset),
    );

    player.play();

    // Mark all items as current before the animation
    this.itemsElements.forEach((item) => {
      this.renderer.addClass(item.nativeElement, "current");
    });

    player.onDone(() => {
      this.itemsElements?.forEach((item, index) => {
        if (this.currentSlide !== index) {
          // Remove class current to all items but the actual current one
          this.renderer.removeClass(item.nativeElement, "current");
        }
      });
    });
  }
}
