<h2 mat-dialog-title>{{ data.title | translate }}</h2>
<mat-dialog-content>
  <p>{{ data.message | translate }}</p>
  <ul *ngIf="data.details">
    <li class="email-item" *ngFor="let d of data.details | keys">
      {{ data.details[d] }}
    </li>
  </ul>
  <div *ngIf="data.moreInfo" class="more-info">
    <a (click)="toggleMoreInfo = !toggleMoreInfo">More info</a>
    <div *ngIf="toggleMoreInfo">
      <code [innerHTML]="data.moreInfo"></code>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="button-orange" [mat-dialog-close]="">
    {{ "shared.cancel" | translate }}
  </button>
</mat-dialog-actions>
