import { ImageEntity } from "../entities/file/image.entity";

export const AnyToImageEntityMapper = (from: any) =>
  new ImageEntity(
    from.id,
    from.title,
    from.description,
    from.url,
    from.imagePublicId,
    from.videoUrl,
    from.widthXHeight,
    from.fileType,
    from.videoDuration,
    from.signUpBackgroundImage,
    from.signUpLogo,
    from.brandPartnersImportId,
    from.brandPartnersImportToken,
    from.publicUrl,
    from.isNewUpload,
  );
