import { PostFileMediaType } from "../../enums/campaignPost.enums";

export class ImageEntity {
  constructor(
    public readonly id: number,
    public readonly title: string,
    public readonly description: string,
    public readonly url: string,
    public readonly imagePublicId: string,
    public readonly videoUrl: string | undefined,
    public readonly widthXHeight: string,
    public readonly fileType: PostFileMediaType,
    public readonly videoDuration: number,
    public readonly signUpBackgroundImage: string,
    public readonly signUpLogo: string,
    public readonly brandPartnersImportId: number,
    public readonly brandPartnersImportToken: string,
    public readonly publicUrl: string,
    public readonly isNewUpload: boolean,
  ) {}
}
