import { PartnerLinkedInPostLog } from "./partnerLinkedInPostLog";
import { SerializableObject } from "./serializable-object";

import { PostPlatform } from "../enums/campaignPost.enums";
import { PostLog } from "./postLog";

import { PartnerFacebookPostLog } from "./partnerFacebookPostLog";
import { PartnerGoogleAdPostLog } from "./partnerGoogleAdPostLog";

export class PartnerCampaignCalendar extends SerializableObject {
  id!: number;
  calendarPosts!: PostLog[];
  title!: string;
  order!: number;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "calendarPosts":
        return propertyValue.map((postData: any) => {
          switch (postData.platform) {
            case PostPlatform.Facebook:
              return new PartnerFacebookPostLog(postData);
            case PostPlatform.Google:
              return new PartnerGoogleAdPostLog(postData);
            case PostPlatform.LinkedIn:
              return new PartnerLinkedInPostLog(postData);
          }
        });
      default:
        return propertyValue;
    }
  }
}
