import { PostPlatform } from "../enums/campaignPost.enums";
import { PartnerCampaignPost } from "./partnerCampaignPost";
import { ImageEntity } from "../entities/file/image.entity";
import { AnyToImageEntityMapper } from "../mappers/image.entity.mapper";

export class PartnerCampaignGoogleAdPost extends PartnerCampaignPost {
  descriptionFive!: string;
  descriptionFour!: string;
  descriptionOne!: string;
  descriptionThree!: string;
  descriptionTwo!: string;
  googleAdLogoUrl!: string;
  headlineFive!: string;
  headlineFour!: string;
  headlineOne!: string;
  headlineThree!: string;
  headlineTwo!: string;
  longHeadline!: string;
  partnerCompanyName!: string;
  partnerCompanyAdditionalInformation!: string;
  images!: ImageEntity[];
  platform = PostPlatform.Google;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "images":
        return propertyValue.map(AnyToImageEntityMapper);
      default:
        return super.coercePropertyType(propertyName, propertyValue);
    }
  }
}
