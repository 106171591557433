import { Injectable } from "@angular/core";
import { Mapper } from "../../../../../harmony/core";
import { UserPermissions } from "../../domain/models/user-permissions";
import {
  UserPermissionsKey,
  UserPermissionsEntity,
} from "../entities/user-permissions.entity";

@Injectable()
export class UserPermissionsEntityToUserPermissionsMapper
  implements Mapper<UserPermissionsEntity, UserPermissions>
{
  public map(permissionsAvailable: UserPermissionsEntity): UserPermissions {
    return Object.values(UserPermissionsKey).reduce(
      (permissions, permission) =>
        permissions.set(permission, permissionsAvailable.includes(permission)),
      new Map<UserPermissionsKey, boolean>(),
    );
  }
}
