import { Component, Input, OnInit } from "@angular/core";
import {
  BrandCampaignCalendarPost,
  BrandCampaignFacebookCalendarPost,
} from "../../../models/brandCampaignCalendarPost";
import { BrandFacebookPostLog } from "../../../models/brandFacebookPostLog";
import { CampaignPostContentType } from "../../../enums/campaignPost.enums";
import { Partner } from "../../../models/partner";
import { PartnerCampaignCalendarFacebookPost } from "../../../models/partnerCampaignCalendarFacebookPost";
import { PartnerCampaignCalendarPost } from "../../../models/partnerCampaignCalendarPost";
import { PartnerCampaignFacebookPost } from "../../../models/partnerCampaignFacebookPost";
import { PartnerFacebookPostLog } from "../../../models/partnerFacebookPostLog";

export enum FacebookPostPreviewType {
  Instagram,
  Facebook,
}

export enum MockBorderType {
  Full,
  RightEmpty,
}

@Component({
  selector: "app-facebook-post-mock-up",
  templateUrl: "./facebook-post-mock-up.component.html",
  styleUrls: ["./facebook-post-mock-up.component.scss"],
})
export class FacebookPostMockUpComponent implements OnInit {
  @Input({ required: true }) public post!:
    | PartnerCampaignFacebookPost
    | PartnerFacebookPostLog
    | BrandFacebookPostLog
    | BrandCampaignFacebookCalendarPost
    | PartnerCampaignCalendarFacebookPost;
  @Input() public partner?: Partner;
  @Input() public borderType: MockBorderType = MockBorderType.Full;
  @Input() public isForList = false;
  @Input() public noMinHeight = false;
  @Input() public noBorder = false;
  @Input() public showFooterReactions = true;
  @Input() public showFooterStats = true;
  @Input() public showInstagramPreviewButton = false;

  protected readonly ContentType = CampaignPostContentType;
  protected facebookPagePicture = "";
  protected facebookPageName = "";
  protected readonly MockBorderType = MockBorderType;
  protected postingToPlatformFailed = false;
  protected postLink?: string;
  protected postDate!: Date;

  public ngOnInit(): void {
    if (
      (this.post instanceof PartnerCampaignFacebookPost ||
        this.post instanceof PartnerCampaignCalendarPost) &&
      this.partner
    ) {
      this.facebookPagePicture = this.partner.facebookPagePicture ?? "";
      this.facebookPageName = this.partner.facebookPageName ?? "";
    } else if (this.isFacebookPostLog(this.post)) {
      this.facebookPagePicture = this.post.facebookPagePicture;
      this.facebookPageName = this.post.facebookPageName;
      this.postingToPlatformFailed = this.post.postingToPlatformFailed ?? false;
      this.postLink = this.post.link;
    }

    if (!this.facebookPagePicture) {
      this.facebookPagePicture = "/assets/images/avatar-default.jpg";
    }

    if (!this.facebookPageName) {
      this.facebookPageName = "shared.yourFacebookPageName";
    }

    this.postDate = this.getPostDate();
  }

  private isFacebookPostLog(
    value: unknown,
  ): value is PartnerFacebookPostLog | BrandFacebookPostLog {
    return (
      value instanceof PartnerFacebookPostLog ||
      value instanceof BrandFacebookPostLog
    );
  }

  private isCampaignCalendarPost(
    value: unknown,
  ): value is BrandCampaignCalendarPost | PartnerCampaignCalendarPost {
    return (
      value instanceof BrandCampaignCalendarPost ||
      value instanceof PartnerCampaignCalendarPost
    );
  }

  private getPostDate(): Date {
    if (
      this.isFacebookPostLog(this.post) &&
      this.post.publishedByPlatform &&
      this.post.publishedByPlatformAt
    ) {
      return new Date(this.post.publishedByPlatformAt);
    } else if (
      this.isFacebookPostLog(this.post) &&
      this.post.scheduledPublishDate
    ) {
      return this.post.scheduledPublishDate;
    } else if (this.post instanceof PartnerFacebookPostLog && this.post.date) {
      return this.post.date;
    } else if (
      this.isCampaignCalendarPost(this.post) &&
      this.post.suggestedPublishDate
    ) {
      return this.post.suggestedPublishDate;
    } else {
      return new Date();
    }
  }
}
