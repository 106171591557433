import { Directive, Input } from "@angular/core";

@Directive({
  selector: "[carouselItem]",
})
export class CarouselItemDirective {
  @Input() public image = "";
  @Input() public text = "";
  @Input() public link = "";
  @Input() public videoUrl = "";
}

@Directive({
  selector: ".carousel-item",
})
export class CarouselItemElementDirective {}
