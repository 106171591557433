import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { BrandCampaignGoogleCalendarPost } from "../../../models/brandCampaignCalendarPost";
import { BrandGoogleAdPostLog } from "../../../models/brandGoogleAdPostLog";
import { Partner } from "../../../models/partner";
import { PartnerCampaignGoogleCalendarPost } from "../../../models/partnerCampaignCalendarPost";
import { PartnerCampaignGoogleAdPost } from "../../../models/partnerCampaignGoogleAdPost";
import { PartnerGoogleAdPostLog } from "../../../models/partnerGoogleAdPostLog";

@Component({
  selector: "app-google-ad-mock-up",
  templateUrl: "./google-ad-mock-up.component.html",
  styleUrls: ["./google-ad-mock-up.component.scss"],
})
export class GoogleAdMockUpComponent implements OnInit, OnDestroy {
  @Input({ required: true }) post!:
    | PartnerGoogleAdPostLog
    | PartnerCampaignGoogleAdPost
    | BrandGoogleAdPostLog
    | BrandCampaignGoogleCalendarPost;

  @Input() formatWrapped = true;
  @Input() noMinHeight = false;
  @Input() partner?: Partner;

  public googleAdLogoUrl?: string;
  public showNoLogo = false;
  private subscriptions = new Subscription();

  public ngOnInit(): void {
    this.setPartnerLogo();
  }

  public ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  public setPartnerLogo() {
    if (
      this.post instanceof PartnerCampaignGoogleAdPost ||
      this.post instanceof PartnerCampaignGoogleCalendarPost
    ) {
      this.googleAdLogoUrl = this.partner?.googleAdsLogoUrl;
    } else if (
      this.post instanceof PartnerGoogleAdPostLog ||
      this.post instanceof BrandGoogleAdPostLog
    ) {
      this.googleAdLogoUrl = this.post.googleAdLogoUrl;
    }

    if (!this.googleAdLogoUrl) {
      this.showNoLogo = true;
      this.googleAdLogoUrl = "/assets/images/google-ads-retailer-logo.png";
    }
  }

  public getAdditionalInfo(): string {
    if (
      this.post instanceof PartnerGoogleAdPostLog ||
      this.post instanceof BrandGoogleAdPostLog
    ) {
      return this.post.partnerCompanyAdditionalInformation;
    }
    return "";
  }
}
