import { SerializableObject } from "./serializable-object";
import { PartnerCampaignPost } from "./partnerCampaignPost";
import { PartnerCampaignGoogleAdPost } from "./partnerCampaignGoogleAdPost";
import { PartnerCampaignFacebookPost } from "./partnerCampaignFacebookPost";
import { PartnerCampaignLinkedInPost } from "./partnerCampaignLinkedInPost";
import { BannerEntity } from "../entities/file/banner.entity";
import { VideoEntity } from "../entities/file/video.entity";
import { PostPlatform } from "../enums/campaignPost.enums";
import { AnyToBannerEntityMapper } from "../mappers/banner.entity.mapper";
import { AnyToVideoEntityMapper } from "../mappers/video.entity.mapper";

export class PartnerCampaignCategory extends SerializableObject {
  id!: number;
  active!: boolean;
  images!: BannerEntity[];
  order!: number;
  posts!: PartnerCampaignPost[];
  subtitle!: string;
  title!: string;
  videos!: VideoEntity[];

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "images":
        return propertyValue.map(AnyToBannerEntityMapper);
      case "videos":
        return propertyValue.map(AnyToVideoEntityMapper);
      case "posts":
        return propertyValue.map((postData: any) => {
          switch (postData.platform) {
            case PostPlatform.Facebook:
              return new PartnerCampaignFacebookPost(postData);
            case PostPlatform.Google:
              return new PartnerCampaignGoogleAdPost(postData);
            case PostPlatform.LinkedIn:
              return new PartnerCampaignLinkedInPost(postData);
          }
        });
      default:
        return propertyValue;
    }
  }
}
