import { PaginatedListEntity } from "../../../../shared/services/responses/paginated-list-entity";
import { Brand } from "../../domain/models/brand";
import { BrandAddress } from "../../domain/models/brand-address";

export class BrandAddressEntityToBrandAddressMapper {
  public map(from: any): BrandAddress {
    return new BrandAddress(
      from.id,
      from.street,
      from.number,
      from.postalCode,
      from.city,
      from.country,
      from.longitude,
      from.latitude,
    );
  }
}

export class BrandEntityToBrandMapper {
  constructor(
    private readonly brandAddressMapper: BrandAddressEntityToBrandAddressMapper,
  ) {}

  public map(from: any): Brand {
    return new Brand(
      from.id,
      from.companyName,
      from.logo,
      from.phone,
      from.address ? this.brandAddressMapper.map(from.address) : undefined,
      from.contactPersonTitle,
      from.contactPersonFirstName,
      from.contactPersonLastName,
      from.accessLevel,
      from.invitePartnersEnabled,
      from.campaignBudgetType,
      from.whiteLabelExternalId,
    );
  }
}

export class BrandListEntityToBrandsMapper {
  constructor(private readonly brandMapper: BrandEntityToBrandMapper) {}

  public map(from: PaginatedListEntity<Brand>): Brand[] {
    return from.items.map((item) => this.brandMapper.map(item));
  }
}
