import { Component, Input, OnInit } from "@angular/core";
import { ImageEntity } from "../../../../entities/file/image.entity";
import { BrandCampaignLinkedInPost } from "../../../../models/brandCampaignLinkedInPost";
import { PartnerLinkedInPostLog } from "../../../../models/partnerLinkedInPostLog";
import { PartnerCampaignLinkedInPost } from "../../../../models/partnerCampaignLinkedInPost";

@Component({
  selector: "app-linkedin-multi-image-mock-up",
  templateUrl: "./linkedin-multi-image-mock-up.component.html",
  styleUrls: ["./linkedin-multi-image-mock-up.component.scss"],
})
export class LinkedInMultiImageMockUpComponent implements OnInit {
  @Input() public post!:
    | BrandCampaignLinkedInPost
    | PartnerLinkedInPostLog
    | PartnerCampaignLinkedInPost;
  public numImages = 0;
  public totalImages = 0;
  public imagesToShow: ImageEntity[] = [];

  public ngOnInit(): void {
    this.totalImages = this.post.images?.length || 0;
    this.imagesToShow = this.post.images?.slice(0, 5) || [];
    this.numImages = this.imagesToShow.length;
  }

  public isSecondRow(index: number): boolean {
    const threeOrFourImagesCondition =
      (this.numImages === 3 || this.numImages === 4) && index > 0;
    const fiveImagesCondition = this.numImages === 5 && index > 1;
    return threeOrFourImagesCondition || fiveImagesCondition;
  }

  public isTwoImagesRow(index: number): boolean {
    return (
      this.numImages === 2 ||
      (this.numImages === 3 && this.isSecondRow(index)) ||
      (this.numImages === 5 && !this.isSecondRow(index))
    );
  }
}
