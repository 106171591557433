[
  {
    "alpha2": "af",
    "alpha3": "afg",
    "id": 4,
    "name": "Afghanistan"
  },
  {
    "alpha2": "za",
    "alpha3": "zaf",
    "id": 710,
    "name": "Afrique du Sud"
  },
  {
    "alpha2": "al",
    "alpha3": "alb",
    "id": 8,
    "name": "Albanie"
  },
  {
    "alpha2": "dz",
    "alpha3": "dza",
    "id": 12,
    "name": "Algérie"
  },
  {
    "alpha2": "de",
    "alpha3": "deu",
    "id": 276,
    "name": "Allemagne"
  },
  {
    "alpha2": "ad",
    "alpha3": "and",
    "id": 20,
    "name": "Andorre"
  },
  {
    "alpha2": "ao",
    "alpha3": "ago",
    "id": 24,
    "name": "Angola"
  },
  {
    "alpha2": "ag",
    "alpha3": "atg",
    "id": 28,
    "name": "Antigua-et-Barbuda"
  },
  {
    "alpha2": "sa",
    "alpha3": "sau",
    "id": 682,
    "name": "Arabie saoudite"
  },
  {
    "alpha2": "ar",
    "alpha3": "arg",
    "id": 32,
    "name": "Argentine"
  },
  {
    "alpha2": "am",
    "alpha3": "arm",
    "id": 51,
    "name": "Arménie"
  },
  {
    "alpha2": "au",
    "alpha3": "aus",
    "id": 36,
    "name": "Australie"
  },
  {
    "alpha2": "at",
    "alpha3": "aut",
    "id": 40,
    "name": "Autriche"
  },
  {
    "alpha2": "az",
    "alpha3": "aze",
    "id": 31,
    "name": "Azerbaïdjan"
  },
  {
    "alpha2": "bs",
    "alpha3": "bhs",
    "id": 44,
    "name": "Bahamas"
  },
  {
    "alpha2": "bh",
    "alpha3": "bhr",
    "id": 48,
    "name": "Bahreïn"
  },
  {
    "alpha2": "bd",
    "alpha3": "bgd",
    "id": 50,
    "name": "Bangladesh"
  },
  {
    "alpha2": "bb",
    "alpha3": "brb",
    "id": 52,
    "name": "Barbade"
  },
  {
    "alpha2": "by",
    "alpha3": "blr",
    "id": 112,
    "name": "Biélorussie"
  },
  {
    "alpha2": "be",
    "alpha3": "bel",
    "id": 56,
    "name": "Belgique"
  },
  {
    "alpha2": "bz",
    "alpha3": "blz",
    "id": 84,
    "name": "Belize"
  },
  {
    "alpha2": "bj",
    "alpha3": "ben",
    "id": 204,
    "name": "Bénin"
  },
  {
    "alpha2": "bt",
    "alpha3": "btn",
    "id": 64,
    "name": "Bhoutan"
  },
  {
    "alpha2": "bo",
    "alpha3": "bol",
    "id": 68,
    "name": "Bolivie"
  },
  {
    "alpha2": "ba",
    "alpha3": "bih",
    "id": 70,
    "name": "Bosnie-Herzégovine"
  },
  {
    "alpha2": "bw",
    "alpha3": "bwa",
    "id": 72,
    "name": "Botswana"
  },
  {
    "alpha2": "br",
    "alpha3": "bra",
    "id": 76,
    "name": "Brésil"
  },
  {
    "alpha2": "bn",
    "alpha3": "brn",
    "id": 96,
    "name": "Brunei"
  },
  {
    "alpha2": "bg",
    "alpha3": "bgr",
    "id": 100,
    "name": "Bulgarie"
  },
  {
    "alpha2": "bf",
    "alpha3": "bfa",
    "id": 854,
    "name": "Burkina Faso"
  },
  {
    "alpha2": "bi",
    "alpha3": "bdi",
    "id": 108,
    "name": "Burundi"
  },
  {
    "alpha2": "kh",
    "alpha3": "khm",
    "id": 116,
    "name": "Cambodge"
  },
  {
    "alpha2": "cm",
    "alpha3": "cmr",
    "id": 120,
    "name": "Cameroun"
  },
  {
    "alpha2": "ca",
    "alpha3": "can",
    "id": 124,
    "name": "Canada"
  },
  {
    "alpha2": "cv",
    "alpha3": "cpv",
    "id": 132,
    "name": "Cap-Vert"
  },
  {
    "alpha2": "cf",
    "alpha3": "caf",
    "id": 140,
    "name": "République centrafricaine"
  },
  {
    "alpha2": "cl",
    "alpha3": "chl",
    "id": 152,
    "name": "Chili"
  },
  {
    "alpha2": "cn",
    "alpha3": "chn",
    "id": 156,
    "name": "Chine"
  },
  {
    "alpha2": "cy",
    "alpha3": "cyp",
    "id": 196,
    "name": "Chypre (pays)"
  },
  {
    "alpha2": "co",
    "alpha3": "col",
    "id": 170,
    "name": "Colombie"
  },
  {
    "alpha2": "km",
    "alpha3": "com",
    "id": 174,
    "name": "Comores (pays)"
  },
  {
    "alpha2": "cg",
    "alpha3": "cog",
    "id": 178,
    "name": "République du Congo"
  },
  {
    "alpha2": "cd",
    "alpha3": "cod",
    "id": 180,
    "name": "République démocratique du Congo"
  },
  {
    "alpha2": "kr",
    "alpha3": "kor",
    "id": 410,
    "name": "Corée du Sud"
  },
  {
    "alpha2": "kp",
    "alpha3": "prk",
    "id": 408,
    "name": "Corée du Nord"
  },
  {
    "alpha2": "cr",
    "alpha3": "cri",
    "id": 188,
    "name": "Costa Rica"
  },
  {
    "alpha2": "ci",
    "alpha3": "civ",
    "id": 384,
    "name": "Côte d'Ivoire"
  },
  {
    "alpha2": "hr",
    "alpha3": "hrv",
    "id": 191,
    "name": "Croatie"
  },
  {
    "alpha2": "cu",
    "alpha3": "cub",
    "id": 192,
    "name": "Cuba"
  },
  {
    "alpha2": "dk",
    "alpha3": "dnk",
    "id": 208,
    "name": "Danemark"
  },
  {
    "alpha2": "dj",
    "alpha3": "dji",
    "id": 262,
    "name": "Djibouti"
  },
  {
    "alpha2": "do",
    "alpha3": "dom",
    "id": 214,
    "name": "République dominicaine"
  },
  {
    "alpha2": "dm",
    "alpha3": "dma",
    "id": 212,
    "name": "Dominique"
  },
  {
    "alpha2": "eg",
    "alpha3": "egy",
    "id": 818,
    "name": "Égypte"
  },
  {
    "alpha2": "sv",
    "alpha3": "slv",
    "id": 222,
    "name": "Salvador"
  },
  {
    "alpha2": "ae",
    "alpha3": "are",
    "id": 784,
    "name": "Émirats arabes unis"
  },
  {
    "alpha2": "ec",
    "alpha3": "ecu",
    "id": 218,
    "name": "Équateur (pays)"
  },
  {
    "alpha2": "er",
    "alpha3": "eri",
    "id": 232,
    "name": "Érythrée"
  },
  {
    "alpha2": "es",
    "alpha3": "esp",
    "id": 724,
    "name": "Espagne"
  },
  {
    "alpha2": "ee",
    "alpha3": "est",
    "id": 233,
    "name": "Estonie"
  },
  {
    "alpha2": "us",
    "alpha3": "usa",
    "id": 840,
    "name": "États-Unis"
  },
  {
    "alpha2": "et",
    "alpha3": "eth",
    "id": 231,
    "name": "Éthiopie"
  },
  {
    "alpha2": "fj",
    "alpha3": "fji",
    "id": 242,
    "name": "Fidji"
  },
  {
    "alpha2": "fi",
    "alpha3": "fin",
    "id": 246,
    "name": "Finlande"
  },
  {
    "alpha2": "fr",
    "alpha3": "fra",
    "id": 250,
    "name": "France"
  },
  {
    "alpha2": "ga",
    "alpha3": "gab",
    "id": 266,
    "name": "Gabon"
  },
  {
    "alpha2": "gm",
    "alpha3": "gmb",
    "id": 270,
    "name": "Gambie"
  },
  {
    "alpha2": "ge",
    "alpha3": "geo",
    "id": 268,
    "name": "Géorgie (pays)"
  },
  {
    "alpha2": "gh",
    "alpha3": "gha",
    "id": 288,
    "name": "Ghana"
  },
  {
    "alpha2": "gr",
    "alpha3": "grc",
    "id": 300,
    "name": "Grèce"
  },
  {
    "alpha2": "gd",
    "alpha3": "grd",
    "id": 308,
    "name": "Grenade (pays)"
  },
  {
    "alpha2": "gt",
    "alpha3": "gtm",
    "id": 320,
    "name": "Guatemala"
  },
  {
    "alpha2": "gn",
    "alpha3": "gin",
    "id": 324,
    "name": "Guinée"
  },
  {
    "alpha2": "gw",
    "alpha3": "gnb",
    "id": 624,
    "name": "Guinée-Bissau"
  },
  {
    "alpha2": "gq",
    "alpha3": "gnq",
    "id": 226,
    "name": "Guinée équatoriale"
  },
  {
    "alpha2": "gy",
    "alpha3": "guy",
    "id": 328,
    "name": "Guyana"
  },
  {
    "alpha2": "ht",
    "alpha3": "hti",
    "id": 332,
    "name": "Haïti"
  },
  {
    "alpha2": "hn",
    "alpha3": "hnd",
    "id": 340,
    "name": "Honduras"
  },
  {
    "alpha2": "hu",
    "alpha3": "hun",
    "id": 348,
    "name": "Hongrie"
  },
  {
    "alpha2": "in",
    "alpha3": "ind",
    "id": 356,
    "name": "Inde"
  },
  {
    "alpha2": "id",
    "alpha3": "idn",
    "id": 360,
    "name": "Indonésie"
  },
  {
    "alpha2": "ir",
    "alpha3": "irn",
    "id": 364,
    "name": "Iran"
  },
  {
    "alpha2": "iq",
    "alpha3": "irq",
    "id": 368,
    "name": "Irak"
  },
  {
    "alpha2": "ie",
    "alpha3": "irl",
    "id": 372,
    "name": "Irlande (pays)"
  },
  {
    "alpha2": "is",
    "alpha3": "isl",
    "id": 352,
    "name": "Islande"
  },
  {
    "alpha2": "il",
    "alpha3": "isr",
    "id": 376,
    "name": "Israël"
  },
  {
    "alpha2": "it",
    "alpha3": "ita",
    "id": 380,
    "name": "Italie"
  },
  {
    "alpha2": "jm",
    "alpha3": "jam",
    "id": 388,
    "name": "Jamaïque"
  },
  {
    "alpha2": "jp",
    "alpha3": "jpn",
    "id": 392,
    "name": "Japon"
  },
  {
    "alpha2": "jo",
    "alpha3": "jor",
    "id": 400,
    "name": "Jordanie"
  },
  {
    "alpha2": "kz",
    "alpha3": "kaz",
    "id": 398,
    "name": "Kazakhstan"
  },
  {
    "alpha2": "ke",
    "alpha3": "ken",
    "id": 404,
    "name": "Kenya"
  },
  {
    "alpha2": "kg",
    "alpha3": "kgz",
    "id": 417,
    "name": "Kirghizistan"
  },
  {
    "alpha2": "ki",
    "alpha3": "kir",
    "id": 296,
    "name": "Kiribati"
  },
  {
    "alpha2": "kw",
    "alpha3": "kwt",
    "id": 414,
    "name": "Koweït"
  },
  {
    "alpha2": "la",
    "alpha3": "lao",
    "id": 418,
    "name": "Laos"
  },
  {
    "alpha2": "ls",
    "alpha3": "lso",
    "id": 426,
    "name": "Lesotho"
  },
  {
    "alpha2": "lv",
    "alpha3": "lva",
    "id": 428,
    "name": "Lettonie"
  },
  {
    "alpha2": "lb",
    "alpha3": "lbn",
    "id": 422,
    "name": "Liban"
  },
  {
    "alpha2": "lr",
    "alpha3": "lbr",
    "id": 430,
    "name": "Liberia"
  },
  {
    "alpha2": "ly",
    "alpha3": "lby",
    "id": 434,
    "name": "Libye"
  },
  {
    "alpha2": "li",
    "alpha3": "lie",
    "id": 438,
    "name": "Liechtenstein"
  },
  {
    "alpha2": "lt",
    "alpha3": "ltu",
    "id": 440,
    "name": "Lituanie"
  },
  {
    "alpha2": "lu",
    "alpha3": "lux",
    "id": 442,
    "name": "Luxembourg (pays)"
  },
  {
    "alpha2": "mk",
    "alpha3": "mkd",
    "id": 807,
    "name": "Macédoine du Nord"
  },
  {
    "alpha2": "mg",
    "alpha3": "mdg",
    "id": 450,
    "name": "Madagascar"
  },
  {
    "alpha2": "my",
    "alpha3": "mys",
    "id": 458,
    "name": "Malaisie"
  },
  {
    "alpha2": "mw",
    "alpha3": "mwi",
    "id": 454,
    "name": "Malawi"
  },
  {
    "alpha2": "mv",
    "alpha3": "mdv",
    "id": 462,
    "name": "Maldives"
  },
  {
    "alpha2": "ml",
    "alpha3": "mli",
    "id": 466,
    "name": "Mali"
  },
  {
    "alpha2": "mt",
    "alpha3": "mlt",
    "id": 470,
    "name": "Malte"
  },
  {
    "alpha2": "ma",
    "alpha3": "mar",
    "id": 504,
    "name": "Maroc"
  },
  {
    "alpha2": "mh",
    "alpha3": "mhl",
    "id": 584,
    "name": "Îles Marshall (pays)"
  },
  {
    "alpha2": "mu",
    "alpha3": "mus",
    "id": 480,
    "name": "Maurice (pays)"
  },
  {
    "alpha2": "mr",
    "alpha3": "mrt",
    "id": 478,
    "name": "Mauritanie"
  },
  {
    "alpha2": "mx",
    "alpha3": "mex",
    "id": 484,
    "name": "Mexique"
  },
  {
    "alpha2": "fm",
    "alpha3": "fsm",
    "id": 583,
    "name": "États fédérés de Micronésie (pays)"
  },
  {
    "alpha2": "md",
    "alpha3": "mda",
    "id": 498,
    "name": "Moldavie"
  },
  {
    "alpha2": "mc",
    "alpha3": "mco",
    "id": 492,
    "name": "Monaco"
  },
  {
    "alpha2": "mn",
    "alpha3": "mng",
    "id": 496,
    "name": "Mongolie"
  },
  {
    "alpha2": "me",
    "alpha3": "mne",
    "id": 499,
    "name": "Monténégro"
  },
  {
    "alpha2": "mz",
    "alpha3": "moz",
    "id": 508,
    "name": "Mozambique"
  },
  {
    "alpha2": "mm",
    "alpha3": "mmr",
    "id": 104,
    "name": "Birmanie"
  },
  {
    "alpha2": "na",
    "alpha3": "nam",
    "id": 516,
    "name": "Namibie"
  },
  {
    "alpha2": "nr",
    "alpha3": "nru",
    "id": 520,
    "name": "Nauru"
  },
  {
    "alpha2": "np",
    "alpha3": "npl",
    "id": 524,
    "name": "Népal"
  },
  {
    "alpha2": "ni",
    "alpha3": "nic",
    "id": 558,
    "name": "Nicaragua"
  },
  {
    "alpha2": "ne",
    "alpha3": "ner",
    "id": 562,
    "name": "Niger"
  },
  {
    "alpha2": "ng",
    "alpha3": "nga",
    "id": 566,
    "name": "Nigeria"
  },
  {
    "alpha2": "no",
    "alpha3": "nor",
    "id": 578,
    "name": "Norvège"
  },
  {
    "alpha2": "nz",
    "alpha3": "nzl",
    "id": 554,
    "name": "Nouvelle-Zélande"
  },
  {
    "alpha2": "om",
    "alpha3": "omn",
    "id": 512,
    "name": "Oman"
  },
  {
    "alpha2": "ug",
    "alpha3": "uga",
    "id": 800,
    "name": "Ouganda"
  },
  {
    "alpha2": "uz",
    "alpha3": "uzb",
    "id": 860,
    "name": "Ouzbékistan"
  },
  {
    "alpha2": "pk",
    "alpha3": "pak",
    "id": 586,
    "name": "Pakistan"
  },
  {
    "alpha2": "pw",
    "alpha3": "plw",
    "id": 585,
    "name": "Palaos"
  },
  {
    "alpha2": "pa",
    "alpha3": "pan",
    "id": 591,
    "name": "Panama"
  },
  {
    "alpha2": "pg",
    "alpha3": "png",
    "id": 598,
    "name": "Papouasie-Nouvelle-Guinée"
  },
  {
    "alpha2": "py",
    "alpha3": "pry",
    "id": 600,
    "name": "Paraguay"
  },
  {
    "alpha2": "nl",
    "alpha3": "nld",
    "id": 528,
    "name": "Pays-Bas"
  },
  {
    "alpha2": "pe",
    "alpha3": "per",
    "id": 604,
    "name": "Pérou"
  },
  {
    "alpha2": "ph",
    "alpha3": "phl",
    "id": 608,
    "name": "Philippines"
  },
  {
    "alpha2": "pl",
    "alpha3": "pol",
    "id": 616,
    "name": "Pologne"
  },
  {
    "alpha2": "pt",
    "alpha3": "prt",
    "id": 620,
    "name": "Portugal"
  },
  {
    "alpha2": "qa",
    "alpha3": "qat",
    "id": 634,
    "name": "Qatar"
  },
  {
    "alpha2": "ro",
    "alpha3": "rou",
    "id": 642,
    "name": "Roumanie"
  },
  {
    "alpha2": "gb",
    "alpha3": "gbr",
    "id": 826,
    "name": "Royaume-Uni"
  },
  {
    "alpha2": "ru",
    "alpha3": "rus",
    "id": 643,
    "name": "Russie"
  },
  {
    "alpha2": "rw",
    "alpha3": "rwa",
    "id": 646,
    "name": "Rwanda"
  },
  {
    "alpha2": "kn",
    "alpha3": "kna",
    "id": 659,
    "name": "Saint-Christophe-et-Niévès"
  },
  {
    "alpha2": "sm",
    "alpha3": "smr",
    "id": 674,
    "name": "Saint-Marin"
  },
  {
    "alpha2": "vc",
    "alpha3": "vct",
    "id": 670,
    "name": "Saint-Vincent-et-les-Grenadines"
  },
  {
    "alpha2": "lc",
    "alpha3": "lca",
    "id": 662,
    "name": "Sainte-Lucie"
  },
  {
    "alpha2": "sb",
    "alpha3": "slb",
    "id": 90,
    "name": "Salomon"
  },
  {
    "alpha2": "ws",
    "alpha3": "wsm",
    "id": 882,
    "name": "Samoa"
  },
  {
    "alpha2": "st",
    "alpha3": "stp",
    "id": 678,
    "name": "Sao Tomé-et-Principe"
  },
  {
    "alpha2": "sn",
    "alpha3": "sen",
    "id": 686,
    "name": "Sénégal"
  },
  {
    "alpha2": "rs",
    "alpha3": "srb",
    "id": 688,
    "name": "Serbie"
  },
  {
    "alpha2": "sc",
    "alpha3": "syc",
    "id": 690,
    "name": "Seychelles"
  },
  {
    "alpha2": "sl",
    "alpha3": "sle",
    "id": 694,
    "name": "Sierra Leone"
  },
  {
    "alpha2": "sg",
    "alpha3": "sgp",
    "id": 702,
    "name": "Singapour"
  },
  {
    "alpha2": "sk",
    "alpha3": "svk",
    "id": 703,
    "name": "Slovaquie"
  },
  {
    "alpha2": "si",
    "alpha3": "svn",
    "id": 705,
    "name": "Slovénie"
  },
  {
    "alpha2": "so",
    "alpha3": "som",
    "id": 706,
    "name": "Somalie"
  },
  {
    "alpha2": "sd",
    "alpha3": "sdn",
    "id": 729,
    "name": "Soudan"
  },
  {
    "alpha2": "ss",
    "alpha3": "ssd",
    "id": 728,
    "name": "Soudan du Sud"
  },
  {
    "alpha2": "lk",
    "alpha3": "lka",
    "id": 144,
    "name": "Sri Lanka"
  },
  {
    "alpha2": "se",
    "alpha3": "swe",
    "id": 752,
    "name": "Suède"
  },
  {
    "alpha2": "ch",
    "alpha3": "che",
    "id": 756,
    "name": "Suisse"
  },
  {
    "alpha2": "sr",
    "alpha3": "sur",
    "id": 740,
    "name": "Suriname"
  },
  {
    "alpha2": "sz",
    "alpha3": "swz",
    "id": 748,
    "name": "Eswatini"
  },
  {
    "alpha2": "sy",
    "alpha3": "syr",
    "id": 760,
    "name": "Syrie"
  },
  {
    "alpha2": "tj",
    "alpha3": "tjk",
    "id": 762,
    "name": "Tadjikistan"
  },
  {
    "alpha2": "tz",
    "alpha3": "tza",
    "id": 834,
    "name": "Tanzanie"
  },
  {
    "alpha2": "td",
    "alpha3": "tcd",
    "id": 148,
    "name": "Tchad"
  },
  {
    "alpha2": "cz",
    "alpha3": "cze",
    "id": 203,
    "name": "Tchéquie"
  },
  {
    "alpha2": "th",
    "alpha3": "tha",
    "id": 764,
    "name": "Thaïlande"
  },
  {
    "alpha2": "tl",
    "alpha3": "tls",
    "id": 626,
    "name": "Timor oriental"
  },
  {
    "alpha2": "tg",
    "alpha3": "tgo",
    "id": 768,
    "name": "Togo"
  },
  {
    "alpha2": "to",
    "alpha3": "ton",
    "id": 776,
    "name": "Tonga"
  },
  {
    "alpha2": "tt",
    "alpha3": "tto",
    "id": 780,
    "name": "Trinité-et-Tobago"
  },
  {
    "alpha2": "tn",
    "alpha3": "tun",
    "id": 788,
    "name": "Tunisie"
  },
  {
    "alpha2": "tm",
    "alpha3": "tkm",
    "id": 795,
    "name": "Turkménistan"
  },
  {
    "alpha2": "tr",
    "alpha3": "tur",
    "id": 792,
    "name": "Turquie"
  },
  {
    "alpha2": "tv",
    "alpha3": "tuv",
    "id": 798,
    "name": "Tuvalu"
  },
  {
    "alpha2": "ua",
    "alpha3": "ukr",
    "id": 804,
    "name": "Ukraine"
  },
  {
    "alpha2": "uy",
    "alpha3": "ury",
    "id": 858,
    "name": "Uruguay"
  },
  {
    "alpha2": "vu",
    "alpha3": "vut",
    "id": 548,
    "name": "Vanuatu"
  },
  {
    "alpha2": "ve",
    "alpha3": "ven",
    "id": 862,
    "name": "Venezuela"
  },
  {
    "alpha2": "vn",
    "alpha3": "vnm",
    "id": 704,
    "name": "Viêt Nam"
  },
  {
    "alpha2": "ye",
    "alpha3": "yem",
    "id": 887,
    "name": "Yémen"
  },
  {
    "alpha2": "zm",
    "alpha3": "zmb",
    "id": 894,
    "name": "Zambie"
  },
  {
    "alpha2": "zw",
    "alpha3": "zwe",
    "id": 716,
    "name": "Zimbabwe"
  }
]
