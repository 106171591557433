import { BrandAddress } from "./brand-address";
import { CampaignBudgetType } from "../../../../shared/enums/campaign.enums";

export enum BrandAccessLevel {
  Full = "FULL",
  Standard = "STANDARD",
  Basic = "BASIC",
}

export class Brand {
  constructor(
    public readonly id: number,
    public readonly companyName: string,
    public logo: string,
    public readonly phone: string,
    public readonly address: BrandAddress | undefined,
    public readonly contactPersonTitle: string,
    public readonly contactPersonFirstName: string,
    public readonly contactPersonLastName: string,
    public readonly accessLevel: BrandAccessLevel,
    public readonly invitePartnersEnabled: boolean,
    public readonly campaignBudgetType: CampaignBudgetType,
    public readonly whiteLabelExternalId: number,
  ) {}

  public get hasFullAccess(): boolean {
    return this.accessLevel === BrandAccessLevel.Full;
  }

  public get hasStandardAccess(): boolean {
    return this.accessLevel === BrandAccessLevel.Standard;
  }

  public get hasBasicAccess(): boolean {
    return this.accessLevel === BrandAccessLevel.Basic;
  }

  public get hasTimeFrameBudget(): boolean {
    return this.campaignBudgetType === CampaignBudgetType.TimeFrame;
  }
}
