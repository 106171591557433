import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  booleanAttribute,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  standalone: true,
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrl: "./loader.component.scss",
  imports: [TranslateModule],
})
export class LoaderComponent implements OnChanges {
  @Input({ required: true }) public readonly text!: string;
  @Input({ transform: booleanAttribute }) public noCover = false;

  @HostBinding("class.no-cover") public noCoverClass = this.noCover;

  public ngOnChanges(): void {
    this.noCoverClass = this.noCover;
  }
}
