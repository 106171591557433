import { Injectable } from "@angular/core";
import { safeLocalStorage, safeSessionStorage } from "../utils/safe-storage";

export enum StorageKeys {
  BrandCampaignPartnersListDisplayColumns = "BRAND_CAMPAIGN_PARTNERS_LIST_DISPLAY_COLUMNS",
  BrandPartnerDisplayColumns = "BRAND_PARTNER_DISPLAY_COLUMNS",
  BrandPartnerSearchHistory = "BRAND_PARTNER_SEARCH_HISTORY",
  CurrentBrandId = "currentBrandId",
  CurrentPartnerId = "currentPartnerId",
  LinkedInConnection = "linkedInConnection",
  SsoTemporaryBrandId = "SSO_TEMPORARY_BRAND_ID",
  User = "User",
  Username = "Username",
  WhiteLabelBrandId = "WHITE_LABEL_BRAND_ID",
  WhiteLabelColorPrimary = "WHITE_LABEL_COLOR_PRIMARY",
  WhiteLabelFooterPadding = "WHITE_LABEL_FOOTER_PADDING",
  WhiteLabelGradientColorHigh = "WHITE_LABEL_GRADIENT_COLOR_HIGH",
  WhiteLabelGradientColorLow = "WHITE_LABEL_GRADIENT_COLOR_LOW",
}

export interface StorageUserItem {
  createdAt?: number;
  email?: string;
  intercomUserHash?: string;
  isVerified?: boolean;
  token?: string;
  userId?: number;
  userRole?: string;
}

export abstract class StorageWrapper {
  abstract store(key: StorageKeys, value: string): void;
  abstract get(key: StorageKeys): string | null;
  abstract getWithFallback(key: StorageKeys): string | null;
  abstract remove(key: StorageKeys): void;
  abstract clearAll(): void;

  public getParsed<T = unknown>(key: StorageKeys): T | undefined {
    const value = this.getWithFallback(key);
    return value !== null ? JSON.parse(value) : undefined;
  }
}

@Injectable()
export class LocalStorageService extends StorageWrapper {
  public store(key: StorageKeys, value: string): void {
    safeLocalStorage.setItem(key, value);
  }

  public get(key: StorageKeys): string | null {
    return safeLocalStorage.getItem(key);
  }

  public getWithFallback(key: StorageKeys): string | null {
    const value = safeLocalStorage.getItem(key);

    if (value === null) {
      return safeSessionStorage.getItem(key);
    }

    return value;
  }

  public remove(key: StorageKeys): void {
    safeLocalStorage.removeItem(key);
  }

  public clearAll(): void {
    safeLocalStorage.clear();
  }
}
