import { SerializableObject } from "./serializable-object";

import { PartnerDashboardPostRow } from "./partnerDashboardPostRow";

import { CampaignCurrency } from "../enums/campaign.enums";

export class PartnerDashboard extends SerializableObject {
  totalOpenInvitations!: number;
  totalJoinedCampaigns!: number;
  totalDeclinedCampaigns!: number;
  totalRunningCampaigns!: number;
  totalCampaignsWithLandingPages!: number;
  totalCampaignsCompleted!: number;
  totalPublishedPosts!: number;
  totalLikesOnPosts!: number;
  totalPublishedAds!: number;
  totalSpentBudget!: number;
  totalPostAdViews!: number;
  totalPeopleReached!: number;
  totalLinkClicksGenerated!: number;
  bestPerformingPosts!: PartnerDashboardPostRow[];
  bestPerformingAds!: PartnerDashboardPostRow[];
  partnerCurrencies!: CampaignCurrency[];
  currency!: CampaignCurrency;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "bestPerformingPosts":
        return propertyValue.map(
          (postData: any) => new PartnerDashboardPostRow(postData),
        );
      case "bestPerformingAds":
        return propertyValue.map(
          (postData: any) => new PartnerDashboardPostRow(postData),
        );
      default:
        return propertyValue;
    }
  }
}
