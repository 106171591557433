interface PartnerKpiChartSeriesData {
  publishedPosts: number;
  publishedAds: number;
  likes: number;
  spentBudget: number;
  postViews: number;
  adViews: number;
  reach: number;
  linkClicks: number;
}

export class PartnerKpiChart {
  constructor(
    public readonly series: Record<string, PartnerKpiChartSeriesData>,
    public readonly publishedPosts: number,
    public readonly publishedAds: number,
    public readonly likes: number,
    public readonly spentBudget: number,
    public readonly postViews: number,
    public readonly adViews: number,
    public readonly reach: number,
    public readonly linkClicks: number,
  ) {}
}
