<div
  class="fieldset small"
  *ngIf="
    uploadHtmlTemplate === UploaderHtmlTemplate.AddIcon ||
    uploadHtmlTemplate === UploaderHtmlTemplate.AddIconOrange
  "
>
  <input
    id="addIconUploaderId"
    type="file"
    class="file-input"
    accept="{{ acceptFormat }}"
    #fileInput
    ngFileSelect
    [style.display]="'none'"
    [options]="options"
    (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput"
    multiple
  />

  <label
    for="addIconUploaderId"
    ngFileSelect
    ngFileDrop
    [options]="options"
    (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput"
    [ngClass]="{ 'is-drop-over': dragOver }"
    class="file-label-small"
  >
    <div
      class="add-button"
      #addButton
      [ngClass]="{
        'add-button--orange':
          uploadHtmlTemplate === UploaderHtmlTemplate.AddIconOrange
      }"
    >
      <mat-icon>add</mat-icon>
    </div>
  </label>
</div>
<span
  *ngIf="uploadHtmlTemplate === UploaderHtmlTemplate.InputOnly"
  class="upload-input"
>
  <input
    id="input"
    type="file"
    #fileInput
    ngFileSelect
    [style.display]="'none'"
    accept="{{ parsedFormats }}"
    [options]="options"
    (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput"
    multiple
  />
</span>
<div
  *ngIf="uploadHtmlTemplate === UploaderHtmlTemplate.Default"
  class="file-uploader"
>
  <label
    for="{{ fileUploaderId }}"
    ngFileSelect
    ngFileDrop
    [options]="options"
    (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput"
    [ngClass]="{ 'is-drop-over': dragOver }"
    class="file-uploader-zone"
  >
    <img src="../../../../assets/images/icons/ic_images.svg" />
    <div>
      <span class="file-uploader-zone-title">{{ uploaderTitle }}</span>
      <span class="file-uploader-zone-subtitle">{{ uploaderSubtitle }}</span>
    </div>
  </label>

  <input
    id="{{ fileUploaderId }}"
    type="file"
    accept="{{ acceptFormat }}"
    #fileInput
    ngFileSelect
    [options]="options"
    (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput"
    multiple
  />
  <ng-container *ngFor="let file of files; let i = index">
    <ng-container *ngIf="file.progress && file.progress.data && uploading">
      <div *ngIf="file.progress.data.percentage < 100">
        <div class="progress">
          <div
            class="progress__area"
            role="progressbar"
            [style.width.%]="file.progress.data.percentage"
          >
            <span class="progress__text"
              >{{ file.progress.data.percentage }}%</span
            >
            <span class="progress__text">{{
              "shared.uploading" | translate
            }}</span>
          </div>
        </div>
      </div>
      <div
        class="progress progress--indeterminate"
        *ngIf="file.progress.data.percentage === 100"
      >
        <mat-progress-bar
          class="mat-progress-bar--wide"
          mode="indeterminate"
        ></mat-progress-bar>
        <span class="progress__text">{{
          "shared.processing" | translate
        }}</span>
      </div>
    </ng-container>
  </ng-container>
</div>
