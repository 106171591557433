import { UploaderResponseError } from "./uploader-response-error";

export class InvalidVideoDurationUploaderResponseError extends UploaderResponseError {
  constructor(
    maxVideoDurationMinutes: number,
    message = "fileUploader.invalidVideoDuration",
  ) {
    super(message);

    this.interpolateParams = {
      maxVideoDurationMinutes: String(maxVideoDurationMinutes),
    };
  }
}
