import {
  CampaignPostContentType,
  LinkedInPostContentType,
  PostPlatform,
} from "../enums/campaignPost.enums";
import { FacebookPost } from "../types/campaignPost.types";
import { SerializableObject } from "./serializable-object";

export class CampaignPost extends SerializableObject {
  id!: number;
  campaignId!: number;
  mediaType!: CampaignPostContentType | LinkedInPostContentType;
  platform!: PostPlatform;
  videoDuration!: number;

  get isRenderedAsImage(): boolean {
    return (
      this.mediaType === CampaignPostContentType.Image ||
      this.mediaType === CampaignPostContentType.Gif ||
      this.mediaType === CampaignPostContentType.LinkPost ||
      this.mediaType === CampaignPostContentType.ResponsiveImage
    );
  }

  public get isFacebookPost(): boolean {
    return this.platform === PostPlatform.Facebook;
  }

  public get isGoogleAd(): boolean {
    return this.platform === PostPlatform.Google;
  }

  public get isLinkedInPost(): boolean {
    return this.platform === PostPlatform.LinkedIn;
  }

  public get canBePostedToInstagram(): boolean {
    if (
      this.mediaType === CampaignPostContentType.Video &&
      this.videoDuration > MAX_INSTAGRAM_VIDEO_DURATION
    ) {
      return false;
    }
    return (
      this.mediaType === CampaignPostContentType.Image ||
      this.mediaType === CampaignPostContentType.Video
    );
  }

  public isFacebookPostInstance(): this is FacebookPost {
    return false;
  }
}

export const MAX_INSTAGRAM_VIDEO_DURATION = 60 * 15; // 15 minutes
