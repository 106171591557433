import { SerializableObject } from "./serializable-object";

import { PartnerCampaign } from "./partnerCampaign";
import { PartnerFacebookPostLog } from "./partnerFacebookPostLog";
import { PartnerGoogleAdPostLog } from "./partnerGoogleAdPostLog";

import { PostPlatform } from "../enums/campaignPost.enums";

export class PartnerDashboardPostRow extends SerializableObject {
  postLog!: PartnerFacebookPostLog;
  campaign!: PartnerCampaign;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "postLog":
        if (propertyValue.platform === PostPlatform.Facebook) {
          return new PartnerFacebookPostLog(propertyValue);
        } else if (propertyValue.platform === PostPlatform.Google) {
          return new PartnerGoogleAdPostLog(propertyValue);
        }
        break;
      case "campaign":
        return new PartnerCampaign(propertyValue);
      default:
        return propertyValue;
    }
  }
}
